import React, { useState } from "react";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  IconButton,
  Skeleton,
  Typography,
  styled,
} from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import DeleteExtractedKnowledgeQuestionDialog from "components/Dialogs/AICreate/DeleteExtractedKnowledgeQuestionDialog";
import AddExtractedKnowledgeQuestionDialog from "components/Dialogs/AICreate/AddExtractedKnowledgeQuestionDialog";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import EditExtractedKnowledgeQuestionDialog from "components/Dialogs/AICreate/EditExtractedKnowledgeQuestionDialog";
import {
  AICreateQuestion,
  AICreateQuestionList,
  DocumentReadResponse,
} from "models/api/response.types";
import { useDispatch, useSelector } from "react-redux";
import remarkGfm from "remark-gfm";
import breaks from "remark-breaks";
import { selectUser } from "store/features/session/slice";
import { terminal_status_names } from "utils/aiHelpers";
import aiCreateService, { useAICreateQuestions } from "api/aiCreateService";
import ReactMarkdown from "react-markdown";
import moment from "moment";
import { applyDocumentDetexification } from "utils/detexify";
import { LoadingButton } from "@mui/lab";
import { addAlert } from "store/features/general/slice";
import clsx from "clsx";

const AccordionWrapper = styled(Accordion)(({ theme }) => ({
  margin: "0 !important",
  boxShadow: "none",
  borderRadius: "4px",
  border: `1px solid ${theme.grey.light}`,
  "&:before": {
    position: "relative",
    opacity: 0,
  },
  "& .MuiAccordionSummary-content": {
    "&.Mui-expanded": {
      margin: "11px 0",
    },
  },
  "& .doc-title": {
    color: theme.palette.secondary.main,
    cursror: "pointer",
    display: "inline-block",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  "& .MuiAccordionDetails-root": {
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
    fontSize: "14px",
    "& .question-container": {
      padding: "1rem",
      background: theme.background.main,
      borderLeft: "2px solid transparent",
      "&.failed": {
        borderColor: theme.red.main,
      },
      "& .question-query": {
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "space-between",
      },
      "& .question-response": {
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "space-between",
      },
      "& .divider": {
        margin: "1rem 0",
        borderTop: `1px dashed #C8C8C8`,
      },
      "& .question-actions": {
        display: "flex",
        flexWrap: "nowrap",
        marginLeft: "1rem",
      },
    },
    "& .actions-container": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      gap: "1rem",
    },
  },
}));

const ExtractedDocQAs: React.FC<{
  projectId: number;
  generatingQuestion: boolean;
  document: DocumentReadResponse;
  questions: AICreateQuestionList;
}> = ({ projectId, generatingQuestion, document: propDocument, questions }) => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const { upsertCachedAICreateQuestion } = useAICreateQuestions(projectId);
  const [editQuestion, setEditQuestion] = useState<
    AICreateQuestion | undefined
  >(undefined);
  const [deleteQuestion, setDeleteQuestion] = useState<
    AICreateQuestion | undefined
  >(undefined);
  const [generatingAIQuestion, setGeneratingAIQuestion] =
    useState<boolean>(false);
  const document = applyDocumentDetexification(propDocument);
  const [showAddQuestionDialog, setShowAddQuestionDialog] =
    useState<boolean>(false);

  const defaultTitle = document?.ui?.show_file_title?.includes(user?.id)
    ? document?.detexiFiedMeta?.title ||
      document?.meta?.title ||
      document.filename ||
      ""
    : document.filename;

  const openDocumentViewer = () => {
    const newPageUrl = `/document/${document.id}/view?organizationId=${document.organization_id}`;
    window.open(newPageUrl, "_blank");
  };

  const generateAIQuestion = () => {
    setGeneratingAIQuestion(true);
    aiCreateService
      .createAICreateQA(projectId, {
        document_id: document.id,
        qa_type: "ai",
        query: "",
      })
      .then(({ data }) => {
        upsertCachedAICreateQuestion(data);
        setGeneratingAIQuestion(false);
      })
      .catch(() => {
        setGeneratingAIQuestion(false);
        dispatch(
          addAlert({
            severity: "error",
            autoHideDuration: 5000,
            alert: {
              message: "Something went wrong. Please try again later.",
            },
          })
        );
      });
  };

  const rerunQA = (qaId: number) => {
    aiCreateService.rerunAICreateQA(projectId, qaId).then(({ data }) => {
      upsertCachedAICreateQuestion(data);
    });
  };

  return (
    <>
      <AccordionWrapper defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreOutlinedIcon />}>
          <Typography className="doc-title" onClick={openDocumentViewer}>
            {defaultTitle}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          {questions.length > 0 ? (
            <>
              {questions.map((question: AICreateQuestion) => {
                const createdFromNow = moment().diff(
                  moment(question.created_at),
                  "seconds"
                );
                const isLoading = !terminal_status_names.includes(
                  question?.response_task_status
                );
                const isFailed =
                  terminal_status_names.includes(
                    question.response_task_status
                  ) &&
                  createdFromNow > 10 &&
                  question.response_task_status !== "success";
                return (
                  <Box
                    key={question.id}
                    className={clsx("question-container", {
                      failed: isFailed,
                    })}
                  >
                    <Box className="question-query">
                      {question.query ? (
                        <Typography variant="body2">
                          {question.query}
                        </Typography>
                      ) : (
                        <Skeleton sx={{ fontSize: "14px", width: "100%" }} />
                      )}
                      <Box className="question-actions">
                        <IconButton
                          color="primary"
                          size="small"
                          disabled={isLoading}
                          onClick={() => {
                            rerunQA(question.id);
                          }}
                        >
                          <RefreshIcon fontSize="small" />
                        </IconButton>
                        <IconButton
                          color="primary"
                          size="small"
                          disabled={isLoading}
                          onClick={() => {
                            setDeleteQuestion(question);
                          }}
                        >
                          <DeleteOutlineOutlinedIcon fontSize="small" />
                        </IconButton>
                      </Box>
                    </Box>
                    <Box className="divider" />
                    {!isLoading ? (
                      <Box className="question-response">
                        <Box>
                          <ReactMarkdown
                            remarkPlugins={[remarkGfm, breaks]}
                            linkTarget="_blank"
                          >
                            {!isFailed
                              ? question.response
                              : "Our servers are currently overloaded, please try again later. Thank you for your patience as we continue to scale our services."}
                          </ReactMarkdown>
                        </Box>
                        <Box className="question-actions">
                          <IconButton
                            color="primary"
                            size="small"
                            disabled={isLoading}
                            onClick={() => {
                              setEditQuestion(question);
                            }}
                          >
                            <EditOutlinedIcon fontSize="small" />
                          </IconButton>
                        </Box>
                      </Box>
                    ) : (
                      <>
                        <Skeleton variant="text" sx={{ fontSize: "14px" }} />
                        <Skeleton variant="text" sx={{ fontSize: "14px" }} />
                        <Skeleton variant="text" sx={{ fontSize: "14px" }} />
                      </>
                    )}
                  </Box>
                );
              })}
            </>
          ) : (
            !generatingQuestion && (
              <Typography
                textAlign="center"
                variant="body2"
                color="textSecondary"
              >
                No results, add new question!
              </Typography>
            )
          )}
          {generatingQuestion && (
            <Box className="question-container">
              <Box className="question-query">
                <Skeleton sx={{ fontSize: "14px", width: "100%" }} />
                <Box className="question-actions">
                  <IconButton color="primary" size="small" disabled>
                    <EditOutlinedIcon fontSize="small" />
                  </IconButton>
                  <IconButton color="primary" size="small" disabled>
                    <RefreshIcon fontSize="small" />
                  </IconButton>
                  <IconButton color="primary" size="small" disabled>
                    <DeleteOutlineOutlinedIcon fontSize="small" />
                  </IconButton>
                </Box>
              </Box>
              <Box className="divider" />
              <Skeleton sx={{ fontSize: "14px", width: "100%" }} />
              <Skeleton sx={{ fontSize: "14px", width: "100%" }} />
              <Skeleton sx={{ fontSize: "14px", width: "100%" }} />
            </Box>
          )}
          <Box className="actions-container">
            <Button
              onClick={() => setShowAddQuestionDialog(true)}
              variant="outlined"
            >
              Add custom question
            </Button>
            <LoadingButton
              onClick={generateAIQuestion}
              variant="outlined"
              loading={generatingAIQuestion}
            >
              Generate AI question
            </LoadingButton>
          </Box>
        </AccordionDetails>
      </AccordionWrapper>
      <EditExtractedKnowledgeQuestionDialog
        question={editQuestion}
        close={() => {
          setEditQuestion(undefined);
        }}
      />
      <DeleteExtractedKnowledgeQuestionDialog
        question={deleteQuestion}
        close={() => {
          setDeleteQuestion(undefined);
        }}
      />
      <AddExtractedKnowledgeQuestionDialog
        projectId={projectId}
        documentId={document.id}
        show={showAddQuestionDialog}
        close={() => {
          setShowAddQuestionDialog(false);
        }}
      />
    </>
  );
};

export default ExtractedDocQAs;

/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable import/prefer-default-export */
import {
  AICellList,
  AIColumnList,
  DocumentReadResponse,
} from "models/api/response.types";
import * as XLSX from "xlsx";
import { applyDocumentDetexification } from "./detexify";

const getRowData = (
  documents: DocumentReadResponse[],
  columns: AIColumnList,
  cells: AICellList,
  userId?: number
) => {
  const rowData: string[][] = [];
  // add columns
  const customColumns = columns.map((column) => column.name || column.query);
  const excelColumns = ["Documents", ...customColumns];
  rowData.push(excelColumns);
  documents.forEach((document) => {
    const docData = applyDocumentDetexification(document);
    const documentTitle =
      docData?.ui?.show_file_title?.includes(userId) && docData.filename
        ? docData.filename
        : docData?.detexiFiedMeta?.title ||
          docData?.meta?.title ||
          docData.filename ||
          "";
    const rowResult = columns.map((column) => {
      for (let i = 0; i < cells.length; i += 1) {
        const data = cells[i];
        if (
          data.document_id === document.id &&
          data.ai_column_id === column.id
        ) {
          return data;
        }
      }
      return undefined;
    });
    const cellsToUse = rowResult.map((cell) => cell?.response || "");
    rowData.push([documentTitle, ...cellsToUse]);
  });

  return rowData;
};

export const exportAITableAsExcel = (
  documents: DocumentReadResponse[],
  columns: AIColumnList,
  cells: AICellList,
  userId?: number
) => {
  const worksheet = XLSX.utils.json_to_sheet(
    getRowData(documents, columns, cells, userId),
    { skipHeader: true }
  );
  const workbook = XLSX.utils.book_new();
  worksheet["!cols"] = [{ wpx: 150 }];
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
  XLSX.writeFile(workbook, `AI-table.xlsx`);
};

import {
  FormControl,
  Select,
  MenuItem,
  Tooltip,
  TextareaAutosize,
  Typography,
  styled,
  Box,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { entryTypes, getSchema } from "utils/bibtexSchema";
import ListInputControl from "components/Tabs/DocumentPropertyTab/ListInputControl";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import displayTextWithMath from "utils/displayTextWithMath";
import detexify from "utils/detexify";
import { DocumentReadResponse } from "models/api/response.types";
import { useSelector } from "react-redux";
import { selectUser } from "store/features/session/slice";

const Root = styled(Box)(({ theme }) => ({
  display: "flex",
  minHeight: "30px",
  flexDirection: "column",
  padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
  "&:hover": {
    background: "#F7F7F7",
    "& .label": {
      color: "inherit",
    },
    "& .info-icon": {
      opacity: 0.75,
    },
  },
  "& .label": {
    display: "flex",
    flex: 1,
    alignItems: "center",
    color: "#8B8B8B",
    transition: "color 0.1s",
    gap: "0.5rem",
    "& .info-icon": {
      opacity: 0,
    },
    "& .checkbox-form-control": {
      margin: 0,
      marginLeft: "auto",
      "& .MuiButtonBase-root.MuiCheckbox-root": {
        padding: 0,
        marginRight: "4px",
      },
    },
  },
  "& .metafield": {
    padding: `${theme.spacing(0.5)} ${theme.spacing(1)}`,
    fontFamily: "inherit",
    lineHeight: 1.43,
    background: "transparent",
    border: "none",
  },
  "& textarea": {
    fontSize: "14px",
    resize: "none",
    fontWeight: "normal",
    filter: "contrast(0.8)",
    letterSpacing: 0.15,
    "&:focus": {
      border: "none",
      outline: "none",
    },
  },
}));

const MetaField: React.FC<{
  disabled: boolean;
  name: string;
  type: string;
  label?: string;
  currentValue: string | string[];
  currentDetexifiedValue?: string | string[];
  onChange: (newvalue: string | string[]) => void;
  showFileTitle?: (show: boolean) => void;
  document?: DocumentReadResponse;
}> = ({
  disabled,
  name,
  type,
  label,
  currentValue,
  currentDetexifiedValue,
  onChange,
  showFileTitle,
  document,
}) => {
  const user = useSelector(selectUser);
  const [value, setValue] = useState<string | string[]>(
    currentDetexifiedValue || currentValue
  );
  const [selectedField, setSelectedField] = useState<boolean>(false);
  const [fieldUpdated, setFieldUpdated] = useState<boolean>(true);
  const [hoveredField, setHoveredField] = useState<boolean>(false);
  const showFileTitleCheckbox =
    name === "title" && document?.doctype !== "stub";
  const showFileTitleChecked =
    showFileTitleCheckbox && document?.ui?.show_file_title?.includes(user?.id);

  // update value if initial value changed (but not if focused)
  useEffect(() => {
    if (!selectedField) {
      if (currentDetexifiedValue) {
        setValue(currentDetexifiedValue);
      } else {
        setValue(currentValue);
      }
      setFieldUpdated(true);
    }
  }, [currentDetexifiedValue, currentValue]);

  const moveFocusToEnd = (
    e: React.FocusEvent<HTMLTextAreaElement, Element>
  ) => {
    const temp_value = e.target.value;
    e.target.value = "";
    e.target.value = temp_value;
  };

  const getInput = (): React.ReactNode => {
    if (type === "string" || type === "text") {
      const valueToDisplay = fieldUpdated
        ? displayTextWithMath(
            currentDetexifiedValue || detexify(value as string, false)
          )
        : value;
      return (
        <>
          {selectedField ? (
            <TextareaAutosize
              className="metafield"
              autoFocus
              id={name}
              name={name}
              onFocus={(e) => moveFocusToEnd(e)}
              onChange={(event) => {
                setValue(event.currentTarget.value);
              }}
              onBlur={() => {
                if (JSON.stringify(value) !== JSON.stringify(currentValue)) {
                  onChange(value);
                  setValue(value);
                  setFieldUpdated(false);
                }
                setSelectedField(false);
              }}
              onKeyDown={(event) => {
                event.stopPropagation();
                if (event.key === "Enter") {
                  event.preventDefault();
                  if (JSON.stringify(value) !== JSON.stringify(currentValue)) {
                    event.currentTarget.blur();
                  }
                }
              }}
              value={value}
            />
          ) : (
            <Typography
              className="metafield"
              variant="body2"
              onClick={() => {
                if (!disabled) {
                  setValue(currentValue);
                  setSelectedField(true);
                }
              }}
              color={
                hoveredField && !valueToDisplay
                  ? "textSecondary"
                  : "textPrimary"
              }
              style={{
                overflow: "hidden",
                overflowWrap: "anywhere",
                ...(!currentDetexifiedValue &&
                  !value && {
                    minHeight: "28px",
                  }),
              }}
            >
              {valueToDisplay ||
                (hoveredField ? `Enter new ${getSchema(name).label}` : "")}
            </Typography>
          )}
        </>
      );
    }
    if (type === "entrytype") {
      const valueToShow =
        value.length > 0 ? value : hoveredField ? "no-value" : "";
      return (
        <FormControl variant="standard">
          <Select
            disabled={disabled}
            value={valueToShow}
            disableUnderline
            autoWidth
            onChange={(event) => {
              setValue(event.target.value);
              if (
                JSON.stringify(event.target.value) !==
                JSON.stringify(currentValue)
              ) {
                onChange(event.target.value);
              }
            }}
            sx={{
              "& .MuiSelect-select:focus": {
                background: "transparent",
              },
              "&.MuiInputBase-root.MuiInputBase-root": {
                background: "transparent",
              },
            }}
          >
            <MenuItem
              disabled
              value="no-value"
              key="no-value"
              sx={{
                display: "none",
              }}
            >
              <Box
                style={{
                  padding: "0 0.5rem",
                }}
              >
                <Typography color="textSecondary" variant="body2">
                  Enter new Entry-type
                </Typography>
              </Box>
            </MenuItem>
            {Object.entries(entryTypes).map(([entryType, info]) => (
              <MenuItem value={entryType} key={entryType}>
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    padding: "0 0.5rem",
                  }}
                >
                  <Typography variant="body2">{entryType}</Typography>
                  <Typography sx={{ fontSize: "12px" }} color="textSecondary">
                    {info.description}
                  </Typography>
                </Box>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      );
    }
    if (type === "list" || name === "author") {
      let arrayValue: string[];
      if (Array.isArray(currentValue)) {
        arrayValue = currentValue as string[];
      } else if (currentValue.includes(" and ")) {
        arrayValue = currentValue.split(" and ");
      } else if (currentValue.includes(" AND ")) {
        arrayValue = currentValue.split(" AND ");
      } else {
        arrayValue = [currentValue as string];
      }

      let arrayDetexifiedValue;
      if (currentDetexifiedValue) {
        if (Array.isArray(currentDetexifiedValue)) {
          arrayDetexifiedValue = currentDetexifiedValue as string[];
        } else if (currentDetexifiedValue.includes(" and ")) {
          arrayDetexifiedValue = currentDetexifiedValue.split(" and ");
        } else if (currentDetexifiedValue.includes(" AND ")) {
          arrayDetexifiedValue = currentDetexifiedValue.split(" AND ");
        } else {
          arrayDetexifiedValue = [currentDetexifiedValue as string];
        }
      } else {
        arrayDetexifiedValue = arrayValue.map((val) => detexify(val, false));
      }

      return (
        <ListInputControl
          hoveredField={hoveredField}
          disabled={disabled}
          propertyName={label || name}
          currentValue={arrayValue}
          currentDetexifiedValue={arrayDetexifiedValue}
          onChange={(newValue: string[]) => {
            setValue(newValue);
            onChange(newValue);
          }}
        />
      );
    }
    return type;
  };

  return (
    <Root
      onMouseEnter={() => {
        if (!disabled) {
          setHoveredField(true);
        }
      }}
      onMouseLeave={() => setHoveredField(false)}
    >
      <label className="label" htmlFor={name}>
        <Typography variant="body2">{getSchema(name).label}</Typography>
        {getSchema(name).default && (
          <Tooltip
            enterDelay={500}
            title={
              <>
                <code>{name}</code> &ndash;{" "}
                <span>{getSchema(name).description}</span>
              </>
            }
            disableInteractive
          >
            <InfoIcon color="primary" fontSize="small" className="info-icon" />
          </Tooltip>
        )}
        {showFileTitleCheckbox && showFileTitle && (
          <FormControlLabel
            className="checkbox-form-control"
            control={
              <Checkbox
                checked={showFileTitleChecked}
                size="small"
                onClick={() => {
                  showFileTitle(!showFileTitleChecked);
                }}
              />
            }
            label={<Typography variant="body2">Show document title</Typography>}
          />
        )}
      </label>
      {getInput()}
    </Root>
  );
};

export default MetaField;

/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from "react";
import {
  Box,
  Button,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCurrentOrganizationId,
  selectUser,
} from "store/features/session/slice";
import { AITable } from "models/api/response.types";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import ImportExportOutlinedIcon from "@mui/icons-material/ImportExportOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import AddIcon from "@mui/icons-material/Add";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import { useAITables } from "api/aiService";
import { useNavigate } from "react-router-dom";
import { useUsers } from "api/userService";
import { isAdmin, isGuest } from "models/components/Permissions.models";
import {
  useOrganizationUsage,
  useOrganizations,
} from "api/organizationService";
import MainLoader from "components/helpers/MainLoader";
import { isTrialExist } from "utils/plans";
import AITableDialog from "components/Dialogs/AITableDialog";
import moment from "moment";
import { setSideBarOpen } from "store/features/browser/slice";
import OpenAiProvider from "components/helpers/OpenAiProvider";
import clsx from "clsx";
import ConfirmationDialog from "components/Dialogs/ConfirmationDialog";
import { companyFeatures } from "company-config";
import routePaths from "routes/routePaths";

const Wrapper = styled(Box)(({ theme }) => ({
  padding: `${theme.spacing(1.5)}`,
  flex: 1,
  overflow: "hidden",
  display: "flex",
  flexDirection: "column",
  "& .header": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& .breadcrumbs": {
      display: "flex",
      alignItems: "center",
      "& .main": {
        cursor: "pointer",
        color: theme.palette.primary.main,
        "&:hover": {
          textDecoration: "underline",
        },
      },
    },
    "& .add-table": {
      marginLeft: "auto",
    },
  },
  "&.error-message": {
    justifyContent: "center",
    alignItems: "center",
    gap: "0.5rem",
    "& img": {
      width: "700px",
      [theme.breakpoints.down("lg")]: {
        width: "500px",
      },
      [theme.breakpoints.down("md")]: {
        width: "400px",
      },
    },
  },
}));

const AiTables: React.FC = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const navigate = useNavigate();
  const currentOrganizationId = useSelector(selectCurrentOrganizationId);
  const smScreen = useMediaQuery(theme.breakpoints.down("lg"));
  const { getCachedOrganizationById } = useOrganizations(user?.id);
  const { users } = useUsers(currentOrganizationId);
  const currentUserRole = users?.find((u) => u.id === user?.id)?.role;
  const { organizationUsage } = useOrganizationUsage(currentOrganizationId);
  const { aiTables, deleteAITableMutation } = useAITables(
    currentOrganizationId
  );
  const [currentOrganization] = getCachedOrganizationById(
    currentOrganizationId || -1
  );
  const [openAiTableDialog, setOpenAiTableDialog] = useState<boolean>(false);
  const [tableToEdit, setTableToEdit] = useState<AITable | undefined>(
    undefined
  );
  const [showDeleteConfirmationDialog, setShowDeleteConfirmationDialog] =
    useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const readOnly =
    (!organizationUsage?.plans?.some((plan) =>
      plan.lookup_key.includes("plan")
    ) &&
      !isTrialExist(currentOrganization?.created_at)) ||
    isGuest(currentUserRole);

  if (!organizationUsage || !aiTables || !currentOrganizationId) {
    return <MainLoader />;
  }

  return (
    <>
      <OpenAiProvider />
      <Wrapper
        className={clsx({
          "error-message": aiTables.length === 0,
        })}
      >
        {aiTables.length > 0 ? (
          <>
            <Box
              className="header"
              sx={{
                ...((smScreen || !readOnly) && {
                  marginBottom: "0.5rem",
                }),
              }}
            >
              {smScreen && (
                <IconButton
                  className="sidebar-close-icon"
                  size="medium"
                  onClick={() => dispatch(setSideBarOpen(true))}
                  color="primary"
                >
                  <MenuOutlinedIcon fontSize="medium" color="action" />
                </IconButton>
              )}
              {!readOnly && (
                <Box
                  sx={{
                    ml: "auto",
                  }}
                >
                  <Button
                    className="add-table"
                    color="primary"
                    variant="outlined"
                    size="small"
                    startIcon={<ImportExportOutlinedIcon fontSize="small" />}
                    onClick={() => {
                      window.open("https://template.mangodoc.ai");
                    }}
                    sx={{
                      mr: 2,
                    }}
                  >
                    Import Templates
                  </Button>
                  <Button
                    className="add-table"
                    color="primary"
                    variant="contained"
                    size="small"
                    startIcon={<AddIcon fontSize="small" />}
                    onClick={() => {
                      setOpenAiTableDialog(true);
                    }}
                  >
                    New Template
                  </Button>
                </Box>
              )}
            </Box>
            <TableContainer
              component={Paper}
              sx={{
                boxShadow: "none",
                border: `1px solid ${theme.grey.light}`,
              }}
            >
              <Table
                sx={{
                  minWidth: 800,
                  boxShadow: "none",
                }}
              >
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell align="right">Source documents</TableCell>
                    <TableCell align="right">Created at</TableCell>
                    <TableCell align="right">Visibility</TableCell>
                    <TableCell align="right">Last edited</TableCell>
                    <TableCell align="center" />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {aiTables.map((table, index) => {
                    const isOwner = table.user_id === user?.id;
                    return (
                      <TableRow
                        hover
                        key={table.id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          navigate(`/browse/ai-table/${table.id}`);
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {table.meta?.name || `Template Library ${index + 1}`}
                        </TableCell>
                        <TableCell align="right">
                          {table.document_ids.length}
                        </TableCell>
                        <TableCell align="right">
                          {moment(table.created_at).fromNow()}
                        </TableCell>
                        <TableCell align="right">
                          {table.visibility[0].toUpperCase() +
                            table.visibility.slice(1)}
                        </TableCell>
                        <TableCell align="right">
                          {moment(
                            table.meta?.last_edited || table.created_at
                          ).fromNow()}
                        </TableCell>
                        <TableCell align="center">
                          {isOwner && (
                            <IconButton
                              size="small"
                              onClick={(e) => {
                                e.stopPropagation();
                                setTableToEdit(table);
                                setAnchorEl(e.currentTarget);
                              }}
                            >
                              <MoreVertOutlinedIcon fontSize="small" />
                            </IconButton>
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <Menu
              id="table-menu"
              anchorEl={anchorEl}
              open={!!anchorEl}
              onClose={() => {
                setTableToEdit(undefined);
                setAnchorEl(null);
              }}
            >
              <MenuItem
                onClick={() => {
                  setOpenAiTableDialog(true);
                  setAnchorEl(null);
                }}
              >
                <ListItemIcon>
                  <ModeEditOutlineOutlinedIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>
                  <Typography variant="body2">Edit</Typography>
                </ListItemText>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setAnchorEl(null);
                  setShowDeleteConfirmationDialog(true);
                }}
              >
                <ListItemIcon>
                  <DeleteOutlineOutlinedIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>
                  <Typography variant="body2">Delete</Typography>
                </ListItemText>
              </MenuItem>
            </Menu>
          </>
        ) : (
          <>
            <img src="/img/no-ai-tables.png" alt="Create Template Library" />
            {!readOnly ? (
              <Button
                color="primary"
                variant="contained"
                size="medium"
                startIcon={<AddIcon fontSize="medium" />}
                onClick={() => {
                  setOpenAiTableDialog(true);
                }}
              >
                Create Template Library
              </Button>
            ) : (
              <>
                {!isGuest(currentUserRole) ? (
                  <>
                    {companyFeatures.settings.canUpgradePlans ? (
                      <>
                        <Typography
                          variant="h6"
                          color="textSecondary"
                          fontWeight={400}
                        >
                          Only users with <strong>Plus</strong> plan or above
                          can access this feature.
                        </Typography>
                        {isAdmin(currentUserRole) && (
                          <Button
                            color="primary"
                            variant="contained"
                            size="medium"
                            onClick={() => {
                              navigate(routePaths.workspaceBilling);
                            }}
                          >
                            Upgrade
                          </Button>
                        )}
                      </>
                    ) : (
                      <Typography
                        variant="h6"
                        color="textSecondary"
                        fontWeight={400}
                      >
                        Your Trial Has Been Expired! Contact Us for More
                        Information
                      </Typography>
                    )}
                  </>
                ) : (
                  <Typography
                    variant="h6"
                    color="textSecondary"
                    fontWeight={400}
                  >
                    No available tables
                  </Typography>
                )}
              </>
            )}
          </>
        )}
      </Wrapper>
      {openAiTableDialog && (
        <AITableDialog
          tableToUpdate={tableToEdit}
          setOpen={(newTable) => {
            if (newTable) {
              navigate(`/browse/ai-table/${newTable.id}`);
            }
            setTableToEdit(undefined);
            setOpenAiTableDialog(false);
          }}
        />
      )}
      <ConfirmationDialog
        show={showDeleteConfirmationDialog}
        title={`Delete ${tableToEdit?.meta?.name || "Template Library"}`}
        description="Are you sure you want to delete the template? All data within this template will be lost."
        buttonText="Delete"
        sensitive
        onClose={(confirmDeletion?: boolean) => {
          setShowDeleteConfirmationDialog(false);
          setTimeout(() => {
            if (confirmDeletion && tableToEdit) {
              deleteAITableMutation.mutate(tableToEdit.id);
            }
            setTableToEdit(undefined);
          }, 300);
        }}
      />
    </>
  );
};

export default AiTables;

/* eslint-disable prefer-const */
import React, { useRef, useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { styled, useTheme } from "@mui/material/styles";
import {
  ListItemText,
  List,
  ListSubheader,
  Popover,
  Typography,
  Button,
  ListItemButton,
  ListItem,
  Box,
  ListItemIcon,
  Skeleton,
  IconButton,
} from "@mui/material";
import {
  selectCurrentOrganizationId,
  selectUser,
  setCurrentOrganizationId,
} from "store/features/session/slice";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import {
  Invite,
  Organization,
  OrganizationList,
} from "models/api/response.types";
import useTelemetry, { telemetryAction } from "utils/useTelemetry";
import CreateEditOrganizationDialog from "components/Dialogs/CreateEditOrganizationDialog";
import InviteMembersDialog from "components/Dialogs/InviteMembersDialog";
import {
  useOrganizations,
  useOrganizationUsage,
} from "api/organizationService";
import { useUserInvites } from "api/inviteService";
import { useNotifications } from "api/notificationService";
import { useQueryClient } from "@tanstack/react-query";
import { isAdmin, isGuest } from "models/components/Permissions.models";
import { AxiosError } from "axios";
import { addAlert } from "store/features/general/slice";
import { AI_CREDIT_PLAN_KEY, isFreePlan } from "utils/plans";
import { useUsers } from "api/userService";
import { setSelectedCollectionId } from "store/features/browser/slice";
import {
  APP_TITLE,
  SMALL_LOGO_SRC,
  LOGO_SRC,
  WWW_URL,
  companyFeatures,
  COMPANY_WEBSITE,
} from "company-config";
import clsx from "clsx";
import LeaveOrganizationDialog from "components/Dialogs/LeaveOrganizationDialog";
import ExitToAppOutlinedIcon from "@mui/icons-material/ExitToAppOutlined";

const PopoverContainer = styled(Popover)(({ theme }) => ({
  "& .MuiPaper-root": {
    padding: theme.spacing(2),
  },
  "& .root": {
    marginBottom: "1rem",
    maxHeight: "300px",
    overflow: "auto",
    width: "100%",
    minWidth: "350px",
    maxWidth: "450px",
    position: "relative",
    padding: 0,
  },
  "& .MuiListItemButton-root": {
    "&.Mui-selected": {
      background: theme.grey.light,
    },
  },
  "& .actions-container": {
    display: "flex",
    justifyContent: "space-between",
  },
  "& .invite-action-container": {
    marginLeft: theme.spacing(1),
  },
}));

const OrganizationButton = styled(ListItemButton)(({ theme }) => ({
  flexGrow: 0,
  padding: theme.spacing(1),
  maxHeight: "51px",
  borderRadius: "4px",
  width: "100%",
  "&.singleWorkspace": {
    background: "transparent",
    cursor: "default",
  },
  "&.Mui-selected": {
    background: theme.grey.light,
  },
  "& .MuiListItemIcon-root": {
    minWidth: "0",
    marginRight: "0.5rem",
  },
  "& .MuiListItemText-root": {
    marginRight: "0.5rem",
    "& p": {
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  },
  "& .skeleton-container": {
    width: "100%",
    "& .skeleton": {
      fontSize: "14px",
      width: "100%",
    },
  },
  "& .selector": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginLeft: "auto",
    "& .top": {
      marginBottom: "-10px",
    },
    "& > svg": {
      fill: theme.grey.main,
    },
  },
}));

const OrganizationSelector: React.FC<{
  workspaceOnly?: boolean;
  isSettingsPage?: boolean;
}> = ({ workspaceOnly, isSettingsPage }) => {
  const theme = useTheme();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const currentOrganizationId = useSelector(selectCurrentOrganizationId);
  const {
    organizations,
    settingsOrganizations,
    organizationQueryKey,
    getCachedOrganizationById,
  } = useOrganizations(user?.id);
  const [currentOrganization] = getCachedOrganizationById(
    currentOrganizationId || -1
  );
  const { organizationUsage } = useOrganizationUsage(currentOrganization?.id);
  const {
    userInvites: invites,
    acceptUserInviteMutation,
    declineUserInviteMutation,
  } = useUserInvites(user);
  const { users } = useUsers(currentOrganization?.id);
  const { logAction } = useTelemetry();
  const { notifications, removeCachedNotification } = useNotifications(user);
  const inviteNotifications = notifications?.filter(
    (notification) => notification?.type === "invite"
  );
  const currentUserRole = users?.find((u) => u.id === user?.id)?.role;
  const [menuAnchor, setMenuAnchor] = React.useState<Element | null>(null);
  const [currentPlan, setCurrentPlan] = useState<string | undefined>(undefined);
  const [editOrganization, setEditOrganization] = useState<
    Organization | undefined
  >(undefined);
  const [orgToLeave, setOrgToLeave] = useState<Organization | undefined>(
    undefined
  );
  const [createOrganizationDialog, setCreateOrganizationDialog] =
    useState<boolean>(false);
  const [inviteMembersDialog, setInviteMembersDialog] =
    useState<boolean>(false);
  const refSelect = useRef(null);
  const filteredInvites = invites?.filter(
    (invite) => !invite.accepted_at && !invite.declined_at
  );
  const personalOrgs =
    organizations?.filter((org: Organization) => org.user_id === user?.id)
      .length || 0;

  const handleMenuClose = () => {
    setMenuAnchor(null);
  };

  const handleMenuOpen = () => {
    setMenuAnchor(refSelect.current);
  };

  const organizationsToUse = useMemo(() => {
    return isSettingsPage ? settingsOrganizations : organizations;
  }, [isSettingsPage, organizations]);

  useEffect(() => {
    if (organizationUsage && currentOrganization) {
      const currentCreditLimit: number =
        organizationUsage.usage_limits.ai_credit_limit;
      const plan = AI_CREDIT_PLAN_KEY[currentCreditLimit];
      if (isFreePlan(currentOrganization?.meta?.plans || [])) {
        setCurrentPlan("Free plan");
      } else if (plan) {
        setCurrentPlan(plan);
      } else {
        setCurrentPlan("Enterprise plan");
      }
    }
  }, [currentOrganization && organizationUsage]);

  const acceptInvite = (invite: Invite) => {
    acceptUserInviteMutation.mutate(invite.id, {
      onSuccess: () => {
        queryClient.invalidateQueries(organizationQueryKey);
        const inviteNotification = inviteNotifications?.find(
          (notification) =>
            parseInt(notification.scope.split(":")[1], 10) === invite.id
        );
        if (inviteNotification) {
          removeCachedNotification(inviteNotification.id);
        }
      },
      onError: (err: any) => {
        const error = err as unknown as AxiosError;
        // org usage exceeded
        if (error?.response?.status === 402) {
          dispatch(
            addAlert({
              severity: "warning",
              autoHideDuration: 5000,
              alert: {
                message: "Usage limit reached for this workspace.",
              },
            })
          );
        }
      },
    });
  };

  const declineInvite = (invite: Invite) => {
    declineUserInviteMutation.mutate(invite.id, {
      onSuccess: () => {
        const inviteNotification = inviteNotifications?.find(
          (notification) =>
            parseInt(notification.scope.split(":")[1], 10) === invite.id
        );
        if (inviteNotification) {
          removeCachedNotification(inviteNotification.id);
        }
      },
    });
  };

  const renderListSection = (listItems: OrganizationList) => (
    <>
      {listItems?.map((org: Organization) => (
        <ListItem key={org.id} disablePadding>
          <ListItemButton
            key={org.id}
            onClick={(event) => {
              event.preventDefault();
              if (currentOrganization?.id !== org.id) {
                dispatch(setCurrentOrganizationId(org.id));
                dispatch(setSelectedCollectionId(undefined));
                logAction(telemetryAction.switch_organization, {
                  organization_id: org.id,
                });
                handleMenuClose();
              }
            }}
            selected={org.id === currentOrganization?.id}
            sx={{
              height: "36px",
              "&:hover .secondary-actions": {
                display: "block",
              },
              "& .secondary-actions": {
                display: "none",
              },
            }}
          >
            <ListItemText
              primary={
                <Typography className="org-name" variant="body2">
                  {org.name}
                </Typography>
              }
            />
            <Box className="secondary-actions">
              {org.user_id === user?.id && !org.is_personal && (
                <IconButton
                  size="small"
                  onClick={(e) => {
                    e.stopPropagation();
                    setEditOrganization(org);
                  }}
                >
                  <EditOutlinedIcon fontSize="inherit" />
                </IconButton>
              )}
              {((Boolean(org.is_personal) && org.user_id !== user?.id) ||
                !org.is_personal) &&
                companyFeatures.settings.canLeaveWorkspace && (
                  <IconButton
                    color="error"
                    size="small"
                    onClick={(e) => {
                      e.stopPropagation();
                      setOrgToLeave(org);
                    }}
                  >
                    <ExitToAppOutlinedIcon fontSize="inherit" />
                  </IconButton>
                )}
            </Box>
          </ListItemButton>
        </ListItem>
      ))}
    </>
  );

  return (
    <>
      <OrganizationButton
        className={clsx("organization-selector", {
          singleWorkspace: companyFeatures.workspace.singleWorkspace,
        })}
        ref={refSelect}
        disabled={!currentOrganization}
        selected={Boolean(menuAnchor)}
        onClick={(event) => {
          if (!companyFeatures.workspace.singleWorkspace) {
            event.preventDefault();
            handleMenuOpen();
          }
        }}
        disableGutters
      >
        <ListItemIcon
          style={
            companyFeatures.workspace.singleWorkspace
              ? {
                  width: "100%",
                }
              : {}
          }
        >
          {companyFeatures.workspace.singleWorkspace ? (
            <img
              onClick={() => {
                window.open(COMPANY_WEBSITE, "_blank");
              }}
              src={LOGO_SRC}
              className="logo"
              alt={APP_TITLE}
              style={{ width: "100%" }}
            />
          ) : (
            <img
              onClick={() => {
                window.open(COMPANY_WEBSITE, "_blank");
              }}
              src={SMALL_LOGO_SRC}
              className="logo"
              alt={APP_TITLE}
              height="35"
              width="35"
            />
          )}
        </ListItemIcon>
        {!companyFeatures.workspace.singleWorkspace &&
          (currentOrganization && currentPlan ? (
            <ListItemText
              primary={
                <Typography fontWeight={500} variant="body2">
                  {currentOrganization?.name
                    ? currentOrganization.name
                    : "Loading..."}
                </Typography>
              }
              secondary={
                companyFeatures.settings.canUpgradePlans &&
                isAdmin(currentUserRole) &&
                !isSettingsPage
                  ? currentPlan
                  : null
              }
            />
          ) : (
            <Box className="skeleton-container">
              <Skeleton variant="text" className="skeleton" />
              {companyFeatures.settings.canUpgradePlans &&
                isAdmin(currentUserRole) && (
                  <Skeleton variant="text" className="skeleton" />
                )}
            </Box>
          ))}
        {!companyFeatures.workspace.singleWorkspace && (
          <Box className="selector">
            <ExpandLessIcon className="top" fontSize="small" color="action" />
            <ExpandMoreIcon
              className="bottom"
              fontSize="small"
              color="action"
            />
          </Box>
        )}
      </OrganizationButton>
      <PopoverContainer
        open={!!menuAnchor}
        onClose={handleMenuClose}
        anchorEl={menuAnchor}
        elevation={3}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <List
          className="root"
          subheader={
            <ListSubheader
              sx={{
                lineHeight: "2rem",
                wordBreak: "break-word",
                padding: 0,
                color: theme.palette.text.primary,
                display: "flex",
                justifyContent: "space-between",
                mb: 1,
              }}
            >
              All workspaces
            </ListSubheader>
          }
        >
          {renderListSection(organizationsToUse || [])}
        </List>
        {!workspaceOnly && (
          <>
            <Box className="actions-container">
              <Button
                type="button"
                variant="contained"
                color="primary"
                size="small"
                disabled={personalOrgs >= 3}
                startIcon={<AddOutlinedIcon />}
                onClick={() => setCreateOrganizationDialog(true)}
              >
                Create workspace
              </Button>
              {!isGuest(currentUserRole) && !isSettingsPage && (
                <Button
                  type="button"
                  variant="outlined"
                  color="primary"
                  size="small"
                  startIcon={<PersonOutlineOutlinedIcon fontSize="small" />}
                  onClick={() => setInviteMembersDialog(true)}
                >
                  Invite member(s)
                </Button>
              )}
            </Box>
            {filteredInvites && filteredInvites.length > 0 && (
              <List
                className="root"
                sx={{
                  marginTop: "1rem",
                }}
              >
                <ListSubheader
                  style={{
                    lineHeight: "2rem",
                    wordBreak: "break-word",
                    padding: "0",
                    color: theme.palette.text.primary,
                  }}
                >
                  Pending invitation
                </ListSubheader>
                {filteredInvites.map((invite) => {
                  return (
                    <ListItem key={invite.id} divider>
                      <ListItemText
                        primary={
                          <Typography
                            fontWeight={500}
                            color="primary"
                            variant="body2"
                          >
                            {invite.organization_name}
                          </Typography>
                        }
                        secondary={
                          <Typography color="textSecondary" variant="body2">
                            From: {invite.invited_by_email}
                          </Typography>
                        }
                      />
                      <Box className="invite-action-container">
                        <Button
                          variant="text"
                          aria-label="Accept"
                          color="primary"
                          onClick={() => {
                            acceptInvite(invite);
                          }}
                        >
                          Accept
                        </Button>
                        <Button
                          variant="text"
                          aria-label="Decline"
                          color="secondary"
                          onClick={() => {
                            declineInvite(invite);
                          }}
                        >
                          Decline
                        </Button>
                      </Box>
                    </ListItem>
                  );
                })}
              </List>
            )}
          </>
        )}
      </PopoverContainer>
      <CreateEditOrganizationDialog
        organization={editOrganization}
        open={createOrganizationDialog || !!editOrganization}
        setOpen={() => {
          if (editOrganization) {
            setEditOrganization(undefined);
          } else {
            setCreateOrganizationDialog(false);
          }
        }}
      />
      {inviteMembersDialog && (
        <InviteMembersDialog
          organization={currentOrganization}
          setOpen={setInviteMembersDialog}
        />
      )}
      <LeaveOrganizationDialog
        organization={orgToLeave}
        close={() => {
          setOrgToLeave(undefined);
        }}
      />
    </>
  );
};

export default OrganizationSelector;

/* eslint-disable no-continue */
/* eslint-disable consistent-return */
/* eslint-disable no-plusplus */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import { DocumentReadResponse } from "models/api/response.types";
import { useSelector, useDispatch } from "react-redux";
import { selectUser } from "store/features/session/slice";
import { documentService } from "api";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useUsers } from "api/userService";
import { isGuest } from "models/components/Permissions.models";
import { useDocuments } from "api/documentService";
import { addAlert } from "store/features/general/slice";
import { LoadingButton } from "@mui/lab";
import TableSourceCell from "./TableSourceCell";

type IRowColumnRow = {
  [key: string]: {
    [nestedKey: string]: any;
  };
};

const Wrapper = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    padding: "24px",
  },
  "& .dialog-title": {
    padding: 0,
    paddingBottom: `${theme.spacing(2)}`,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& h6": {
      display: "-webkit-box",
      WebkitLineClamp: "1",
      WebkitBoxOrient: "vertical",
      overflow: "hidden",
      overflowWrap: "anywhere",
    },
  },
  "& .MuiDialogContent-root": {
    padding: 0,
    paddingRight: "22px",
    position: "relative",
    overflow: "hidden",
    marginBottom: "20px",
    height: "100%",
    maxHeight: "600px",
    display: "flex",
    flexDirection: "column",
    "& .radio-group": {
      flexDirection: "row",
      alignItems: "center",
    },
    "& .description-input": {
      margin: "1rem 0",
    },
    "& .main-container": {
      display: "flex",
      flexDirection: "column",
      overflow: "hidden",
      "& .table-source-container": {
        maxHeight: "470px",
        overflow: "auto",
        minHeight: "170px",
        display: "flex",
        flexDirection: "column",
        gap: "0.05rem",
        "& .row": {
          display: "flex",
          alignItems: "center",
          gap: "0.05rem",
        },
      },
    },
    "& .add-content": {
      border: 0,
      display: "flex",
      alignItems: "center",
      borderRadius: "4px",
      justifyContent: "center",
      cursor: "pointer",
      background: theme.grey.light,
      color: theme.grey.main,
      transition: "all 0.3s",
      "&:hover": {
        color: theme.palette.text.primary,
      },
      "&.column": {
        position: "absolute",
        bottom: "40px",
        right: 0,
        width: "20px",
        minWidth: "20px",
      },
      "&.row": {
        height: "20px",
        marginTop: "4px",
        bottom: "10px",
        width: "100%",
        left: 0,
      },
    },
    "& .loading-container": {
      borderRadius: "4px",
      position: "relative",
      "& .table-source-container": {
        filter: "blur(10px)",
        minHeight: "180px",
      },
      "& .loading-overlay": {
        position: "absolute",
        top: 0,
        left: 0,
        height: "100%",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
    },
  },
  "& .MuiDialogActions-root": {
    padding: 0,
  },
}));

const windowDocument = document;

const generateUniqueKey = (baseName: string, existingKeys: string[]) => {
  let counter = 1;
  let uniqueKey = `${baseName}-${counter}`;
  while (existingKeys.includes(uniqueKey)) {
    counter++;
    uniqueKey = `${baseName}-${counter}`;
  }
  return uniqueKey;
};

const decrementDescriptionKeys = (
  description_keys: { [key: string]: string },
  breakpoint?: number
) => {
  const updatedDescriptions: { [key: string]: string } = {};
  for (const key in description_keys) {
    if (description_keys[key]) {
      const keyNumber = Number(key);
      if (key === "0" && !breakpoint) continue;
      if ((breakpoint !== undefined && keyNumber > breakpoint) || !breakpoint) {
        const decrementedKey = String(keyNumber - 1);
        updatedDescriptions[decrementedKey] = description_keys[key];
      } else {
        updatedDescriptions[key] = description_keys[key];
      }
    }
  }
  return updatedDescriptions;
};

const incrementColumnDescriptionKeys = (column_description: {
  [key: string]: string;
}) => {
  const updatedDescriptions: { [key: string]: string } = {};
  for (const key in column_description) {
    if (column_description[key]) {
      const decrementedKey = String(Number(key) + 1);
      updatedDescriptions[decrementedKey] = column_description[key];
    }
  }
  return updatedDescriptions;
};

const deepCopyNestedObject = (obj: IRowColumnRow): IRowColumnRow => {
  const key = Object.keys(obj)[0];
  return { [key]: { ...obj[key] } };
};

const TableSourceDialog: React.FC<{
  document: DocumentReadResponse;
  close: () => void;
}> = ({ document, close }) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const user = useSelector(selectUser);
  const { users } = useUsers(document.organization_id);
  const { updateDocumentMutation } = useDocuments(document.organization_id);
  const originalMeta = document?.meta;
  const [newDocumentMeta, setNewDocumentMeta] = useState<{
    [key: string]: any;
  }>(document?.meta);
  const [newContent, setNewContent] = useState<any>(undefined);
  const [loading, setLoading] = useState<boolean>(true);
  const [updatingTable, setUpdatingTable] = useState<boolean>(false);
  const currentUserRole = users?.find((u) => u.id === user?.id)?.role;
  const isReadModeOnly = isGuest(currentUserRole);
  const tableRef = useRef<HTMLDivElement>(null);
  const addColumnButtonRef = useRef<HTMLButtonElement>(null);
  const readBy = document?.ui?.read_by;
  const is_new = readBy ? !new Set(readBy).has(user?.id) : false;

  const { data: originalContent } = useQuery({
    queryKey: ["table-source", document.id],
    queryFn: () => documentService.getSourceTableContent(document.id),
  });

  useEffect(() => {
    if (tableRef.current && !isReadModeOnly) {
      const updateButtonHeight = () => {
        if (tableRef.current && addColumnButtonRef.current) {
          const containerHeight = tableRef.current.clientHeight;
          addColumnButtonRef.current.style.height = `${containerHeight - 15}px`;
        }
      };
      updateButtonHeight();

      // Listen for window resize or other events that may change the container's height
      window.addEventListener("resize", updateButtonHeight);

      // Clean up the event listener on component unmount
      return () => {
        window.removeEventListener("resize", updateButtonHeight);
      };
    }
  }, [tableRef, loading, newContent]);

  useEffect(() => {
    if (is_new) {
      updateDocumentMutation.mutate({
        id: document.id,
        payload: {
          ui: {
            ...document.ui,
            read_by: [...document.ui.read_by, user?.id],
          },
        },
      });
    }
  }, [is_new]);

  const title = useMemo(() => {
    return document?.ui?.show_file_title?.includes(user?.id)
      ? document?.detexiFiedMeta?.title ||
          document?.meta?.title ||
          document.filename ||
          ""
      : document.filename;
  }, [document]);

  useEffect(() => {
    if (originalContent) {
      setNewContent(originalContent);
      setLoading(false);
    }
  }, [originalContent]);

  const canUpdateTableContent = useMemo(() => {
    if (originalContent && newContent) {
      return JSON.stringify(originalContent) !== JSON.stringify(newContent);
    }
    return false;
  }, [newContent]);

  const onClose = () => {
    if (!updatingTable) {
      close();
    }
  };

  const canUpdateMeta = useMemo(() => {
    return JSON.stringify(newDocumentMeta) !== JSON.stringify(originalMeta);
  }, [newDocumentMeta, originalMeta]);

  const isRowColumnHeader = useMemo(() => {
    return !!newDocumentMeta?.row_description;
  }, [newDocumentMeta]);

  const columnHeaders = useMemo(() => {
    if (newContent) {
      if (isRowColumnHeader) {
        const mainKey = Object.keys(newContent[0])[0];
        return Object.keys(newContent[0][mainKey]);
      }
      return Object.keys(newContent[0]);
    }
    return undefined;
  }, [newContent, isRowColumnHeader]);

  const canDeleteColumns = useMemo(() => {
    return columnHeaders && columnHeaders.length > 1;
  }, [columnHeaders]);

  const rowHeaders = useMemo(() => {
    if (newContent && isRowColumnHeader) {
      return newContent.map(
        (data: { [key: string]: any }) => Object.keys(data)[0]
      );
    }
    return undefined;
  }, [newContent?.length, isRowColumnHeader]);

  const canDeleteRows = useMemo(() => {
    return rowHeaders && rowHeaders.length > 1;
  }, [rowHeaders]);

  // COLUMN ACTIONS
  const updateColumnHeaderDescription = (value: string, index: number) => {
    setNewDocumentMeta({
      ...newDocumentMeta,
      column_description: {
        ...newDocumentMeta?.column_description,
        [index]: value,
      },
    });
  };

  const updateColumnViewColumnHeaderCellValue = (
    value: string,
    rowId: number,
    keyId: number
  ) => {
    const contentToUpdate = newContent.map((row: any) => ({ ...row }));
    const row = contentToUpdate[rowId];
    const keyValues = Object.keys(row);
    const keyToUpdate = keyValues[keyId];
    contentToUpdate[rowId][keyToUpdate] = value;
    setNewContent(contentToUpdate);
  };

  const updateColumnViewColumnHeader = (newValue: string, index: number) => {
    const updatedData = newContent
      .map((row: any) => ({ ...row }))
      .map((obj: { [key: string]: string }) => {
        const keys = Object.keys(obj);
        if (index < keys.length) {
          const oldKey = keys[index];
          const value = obj[oldKey];

          delete obj[oldKey];

          const updatedObj: { [key: string]: string } = {};
          keys.forEach((key, idx) => {
            if (idx === index) {
              updatedObj[newValue] = value;
            }
            if (key !== oldKey) {
              updatedObj[key] = obj[key];
            }
          });

          return updatedObj;
        }
        return obj;
      });
    setNewContent(updatedData);
  };

  const addColumnHeaderTypeRow = () => {
    if (columnHeaders) {
      const newRow: { [key: string]: string } = {};
      columnHeaders.forEach((header) => {
        newRow[header] = "";
      });
      setNewContent([...newContent, newRow]);
      const element = windowDocument.getElementById("source-table");
      if (element) {
        setTimeout(() => {
          element.scrollTop = element.scrollHeight;
        }, 300);
      }
    }
  };

  const addColumnHeaderTypeColumn = () => {
    if (columnHeaders) {
      const uniqueKey = generateUniqueKey("Column", columnHeaders || []);
      setNewContent(
        newContent.map((rowValue: { [key: string]: string }) => {
          return { ...rowValue, [uniqueKey]: "" };
        })
      );
      const element = windowDocument.getElementById("source-table");
      if (element) {
        setTimeout(() => {
          element.scrollTop = 0;
          element.scrollTo({
            left: element.scrollWidth,
            behavior: "smooth",
          });
        }, 300);
      }
    }
  };

  const deleteColumnHeaderTypeColumn = (col: string, index: number) => {
    // shallow copy preventing from overriding original data
    const newData = newContent.map((row: { [key: string]: string }) => {
      return { ...row };
    });

    // Now we modify the copied data
    newData.forEach((row: { [key: string]: string }) => {
      delete row[col];
    });

    let newColumnDescriptions = { ...newDocumentMeta?.column_description };
    delete newColumnDescriptions[index.toString()];
    newColumnDescriptions = decrementDescriptionKeys(
      newColumnDescriptions,
      index
    );
    setNewContent(newData);
    setNewDocumentMeta({
      ...newDocumentMeta,
      column_description: newColumnDescriptions,
    });
  };

  // ROW-COLUMN ACTIONS

  const updateRowHeaderDescription = (value: string, index: number) => {
    setNewDocumentMeta({
      ...newDocumentMeta,
      row_description: {
        ...newDocumentMeta?.row_description,
        [index]: value,
      },
    });
  };

  const updateRowColumnViewColumnHeader = (newValue: string, index: number) => {
    const contentToUpdate = newContent.map((row: IRowColumnRow) =>
      deepCopyNestedObject(row)
    );
    const newData = contentToUpdate.map((parentObject: IRowColumnRow) => {
      const parentKey = Object.keys(parentObject)[0];
      const childObject = parentObject[parentKey];
      const childObjectKeys = Object.keys(childObject);
      if (index < childObjectKeys.length) {
        const oldKey = childObjectKeys[index];
        const value = childObject[oldKey];

        delete childObject[oldKey];

        const updatedObj: { [key: string]: string } = {};
        childObjectKeys.forEach((key, idx) => {
          if (idx === index) {
            updatedObj[newValue] = value;
          }
          if (key !== oldKey) {
            updatedObj[key] = childObject[key];
          }
        });

        return {
          [parentKey]: updatedObj,
        };
      }
      return {
        [parentKey]: childObject,
      };
    });
    setNewContent(newData);
  };

  const updateRowColumnViewRowHeader = (
    oldValue: string,
    newValue: string,
    index: number
  ) => {
    const contentToUpdate = newContent.map((row: any) => ({ ...row }));
    const row = contentToUpdate[index];
    const keyValue = row[oldValue];

    delete row[oldValue];
    row[newValue] = keyValue;
    contentToUpdate[index] = row;
    setNewContent(contentToUpdate);
  };

  const updateRowColumnViewCellValue = (
    value: string,
    rowIndex: number,
    keyValue: string
  ) => {
    const contentToUpdate = newContent.map((row: IRowColumnRow) =>
      deepCopyNestedObject(row)
    );
    const row = contentToUpdate[rowIndex];
    const key = Object.keys(row)[0];
    contentToUpdate[rowIndex][key][keyValue] = value;
    setNewContent(contentToUpdate);
  };

  const addRowColumnHeaderTypeRow = () => {
    if (columnHeaders && rowHeaders) {
      const uniqueKey = generateUniqueKey("Row", rowHeaders || []);
      const newRowValue: { [key: string]: string } = {};
      columnHeaders.forEach((header) => {
        newRowValue[header] = "";
      });
      setNewContent([
        ...newContent,
        {
          [uniqueKey]: newRowValue,
        },
      ]);
      const element = windowDocument.getElementById("source-table");
      if (element) {
        setTimeout(() => {
          element.scrollTop = element.scrollHeight;
        }, 300);
      }
    }
  };

  const addRowColumnHeaderTypeColumn = () => {
    if (columnHeaders) {
      const uniqueKey = generateUniqueKey("Column", columnHeaders || []);
      setNewContent(
        newContent.map(
          (rowValue: { [key: string]: { [key: string]: string } }) => {
            const value = Object.entries(rowValue);
            return {
              [value[0][0]]: {
                ...value[0][1],
                [uniqueKey]: "",
              },
            };
          }
        )
      );
      const element = windowDocument.getElementById("source-table");
      if (element) {
        setTimeout(() => {
          element.scrollTop = 0;
          element.scrollTo({
            left: element.scrollWidth,
            behavior: "smooth",
          });
        }, 300);
      }
    }
  };

  const deleteRowColumnHeaderTypeColumn = (col: string, index: number) => {
    // Deep copy each row to prevent modifying original data
    const newData = newContent.map(
      (row: { [key: string]: { [key: string]: string } }) => {
        const rowKey = Object.keys(row)[0];
        // Deeply copy the nested object
        const newRowData = { ...row[rowKey] };
        return { [rowKey]: newRowData };
      }
    );

    newData.forEach((row: { [key: string]: { [key: string]: string } }) => {
      const rowKey = Object.keys(row)[0];
      const rowData = row[rowKey];
      delete rowData[col];
    });

    let newColumnDescriptions = { ...newDocumentMeta?.column_description };
    delete newColumnDescriptions[index.toString()];
    newColumnDescriptions = decrementDescriptionKeys(
      newColumnDescriptions,
      index
    );

    setNewContent(newData);
    setNewDocumentMeta({
      ...newDocumentMeta,
      column_description: newColumnDescriptions,
    });
  };

  const deleteRowColumnHeaderTypeRow = (colId: number) => {
    const newData = [...newContent];
    newData.splice(colId, 1);

    let newRowDescriptions = { ...newDocumentMeta?.row_description };
    delete newRowDescriptions[colId.toString()];
    newRowDescriptions = decrementDescriptionKeys(newRowDescriptions, colId);
    setNewContent(newData);
    setNewDocumentMeta({
      ...newDocumentMeta,
      row_description: newRowDescriptions,
    });
  };

  const switchToColumnHeader = () => {
    setLoading(true);
    const uniqueKey = generateUniqueKey("Column", columnHeaders || []);
    const newData = newContent.map((row: any) => {
      const value = Object.entries(row)[0];
      return {
        [uniqueKey]: value[0],
        ...(value[1] as { [key: string]: string }),
      };
    });
    setNewContent(newData);
    const newMeta = { ...newDocumentMeta };
    delete newMeta.row_description;
    setNewDocumentMeta({
      ...newMeta,
      column_description: newMeta?.column_description
        ? incrementColumnDescriptionKeys(newMeta?.column_description)
        : {},
    });
    setTimeout(() => {
      setLoading(false);
    }, 300);
  };

  const switchToRowColumnHeader = () => {
    setLoading(true);
    const uniqueIdentifier: Set<string> = new Set();
    const updatedContent: { [key: string]: { [key: string]: string } }[] = [];
    newContent.forEach((obj: { [key: string]: string }) => {
      let keyValue = Object.values(obj)[0];
      const objectKeys = Object.keys(obj);
      if (!keyValue) {
        keyValue = generateUniqueKey("Row", Array.from(uniqueIdentifier));
      } else if (uniqueIdentifier.has(keyValue)) {
        keyValue = generateUniqueKey(keyValue, Array.from(uniqueIdentifier));
      }
      uniqueIdentifier.add(keyValue);
      updatedContent.push({
        [keyValue]: {
          ...Object.fromEntries(
            Object.entries(obj).slice(objectKeys.length > 1 ? 1 : 0)
          ),
        },
      });
    });
    setNewContent(updatedContent);
    setNewDocumentMeta({
      ...newDocumentMeta,
      column_description: newDocumentMeta?.column_description
        ? decrementDescriptionKeys(newDocumentMeta?.column_description)
        : {},
      row_description: {
        0: "",
      },
    });
    setTimeout(() => {
      setLoading(false);
    }, 300);
  };

  const resetTableData = () => {
    setNewDocumentMeta(document?.meta);
    setNewContent(originalContent);
  };

  const updateTable = () => {
    setUpdatingTable(true);
    updateDocumentMutation.mutate(
      {
        id: document.id,
        payload: {
          ...(canUpdateTableContent && {
            content: newContent,
          }),
          ...(canUpdateMeta && {
            meta: newDocumentMeta,
          }),
        },
      },
      {
        onSuccess: () => {
          queryClient.setQueryData(["table-source", document.id], newContent);
          dispatch(
            addAlert({
              severity: "success",
              autoHideDuration: 3000,
              alert: {
                message: "Table has been successfully updated.",
              },
            })
          );
          close();
          setUpdatingTable(false);
        },
        onError: () => {
          setUpdatingTable(false);
          dispatch(
            addAlert({
              severity: "error",
              autoHideDuration: 3000,
              alert: {
                message: "Something went wrong. Please try again.",
              },
            })
          );
        },
      }
    );
  };

  const generateDisabledCell = () => {
    return (
      <TableSourceCell
        isReadMode={isReadModeOnly}
        value=""
        isColumnHeader
        isDisabled
        setValue={() => {}}
      />
    );
  };

  const loaderOverlay = newContent || [1, 2, 3, 4, 5];

  return (
    <Wrapper open onClose={onClose} fullWidth maxWidth="lg">
      <Box className="dialog-title">
        <Typography variant="h6">{title}</Typography>
        <IconButton onClick={onClose}>
          <Close />
        </IconButton>
      </Box>
      <DialogContent>
        {!isReadModeOnly && (
          <>
            <FormControl fullWidth>
              <RadioGroup defaultValue="column" className="radio-group">
                <FormControlLabel
                  checked={!isRowColumnHeader}
                  onClick={() => {
                    if (isRowColumnHeader) {
                      switchToColumnHeader();
                    }
                  }}
                  value="column"
                  control={<Radio size="small" />}
                  label="Column header"
                />
                <FormControlLabel
                  checked={isRowColumnHeader}
                  onClick={() => {
                    if (!isRowColumnHeader) {
                      switchToRowColumnHeader();
                    }
                  }}
                  value="column-row"
                  control={<Radio size="small" />}
                  label="Row & Column header"
                />
              </RadioGroup>
            </FormControl>
            <TextField
              className="description-input"
              fullWidth
              value={newDocumentMeta?.abstract}
              variant="outlined"
              placeholder="Table description"
              multiline
              maxRows={3}
              error={
                !newDocumentMeta?.abstract ||
                newDocumentMeta?.abstract?.length === 0
              }
              helperText={
                !newDocumentMeta?.abstract ||
                newDocumentMeta?.abstract?.length === 0
                  ? "This table is missing descriptions. The AI relies on the description to understand the nature of the information within the table. Please provide a detailed description if possible."
                  : ""
              }
              size="small"
              onChange={(event) => {
                setNewDocumentMeta({
                  ...newDocumentMeta,
                  abstract: event.target.value,
                });
              }}
            />
          </>
        )}
        <Box className="main-container">
          <Box
            id="source-table"
            ref={tableRef}
            className="table-source-container"
          >
            {newContent && columnHeaders && !loading ? (
              <>
                {/* // column headers */}
                <Box className="row">
                  {isRowColumnHeader && (
                    <>
                      {generateDisabledCell()}
                      {generateDisabledCell()}
                    </>
                  )}
                  {columnHeaders.map((header, index) => {
                    return (
                      <TableSourceCell
                        canDeleteColumn={canDeleteColumns}
                        isReadMode={isReadModeOnly}
                        allColumnHeaders={columnHeaders}
                        key={`${header}-${index}`}
                        isColumnHeader
                        value={header}
                        setValue={(value: string) => {
                          if (isRowColumnHeader) {
                            updateRowColumnViewColumnHeader(value, index);
                          } else {
                            updateColumnViewColumnHeader(value, index);
                          }
                        }}
                        deleteColumn={() => {
                          if (isRowColumnHeader) {
                            deleteRowColumnHeaderTypeColumn(header, index);
                          } else {
                            deleteColumnHeaderTypeColumn(header, index);
                          }
                        }}
                      />
                    );
                  })}
                </Box>
                {/* // column header descriptions */}
                <Box className="row">
                  {isRowColumnHeader && (
                    <>
                      {generateDisabledCell()}
                      {generateDisabledCell()}
                    </>
                  )}
                  {columnHeaders.map((header, index) => {
                    const value = newDocumentMeta?.column_description
                      ? newDocumentMeta?.column_description[index] || ""
                      : "";
                    return (
                      <TableSourceCell
                        isReadMode={isReadModeOnly}
                        key={`${header}-${index}`}
                        isColumnDescription
                        value={value}
                        setValue={(newValue: string) => {
                          updateColumnHeaderDescription(newValue, index);
                        }}
                      />
                    );
                  })}
                </Box>
                {/* // table rows */}
                {newContent.map(
                  (rowData: { [key: string]: any }, idx: number) => {
                    if (isRowColumnHeader) {
                      const rowHeader = Object.keys(rowData)[0];
                      const rowDescription =
                        newDocumentMeta?.row_description[idx] || "";
                      const cellValues: string[] = Object.values(
                        rowData[rowHeader]
                      );
                      return (
                        <Box key={idx} className="row">
                          {/* // row header */}
                          <TableSourceCell
                            canDeleteRow={canDeleteRows}
                            isReadMode={isReadModeOnly}
                            allRowHeaders={rowHeaders}
                            value={rowHeader}
                            isRowHeader
                            setValue={(value: string) => {
                              updateRowColumnViewRowHeader(
                                rowHeader,
                                value,
                                idx
                              );
                            }}
                            deleteRow={() => {
                              deleteRowColumnHeaderTypeRow(idx);
                            }}
                          />
                          {/* // row description */}
                          <TableSourceCell
                            isReadMode={isReadModeOnly}
                            isRowDescription
                            value={rowDescription}
                            setValue={(newValue: string) => {
                              updateRowHeaderDescription(newValue, idx);
                            }}
                          />
                          {/* // row values */}
                          {cellValues.map((cell: string, id: number) => (
                            <TableSourceCell
                              isReadMode={isReadModeOnly}
                              key={`${cell}-${idx}-${id}`}
                              value={cell}
                              setValue={(value: string) => {
                                updateRowColumnViewCellValue(
                                  value,
                                  idx,
                                  columnHeaders[id]
                                );
                              }}
                            />
                          ))}
                        </Box>
                      );
                    }
                    return (
                      <Box key={idx} className="row">
                        {Object.values(rowData).map(
                          (cell: string, id: number) => {
                            return (
                              <TableSourceCell
                                isReadMode={isReadModeOnly}
                                key={`${cell}-${idx}-${id}`}
                                value={cell}
                                setValue={(value: string) => {
                                  updateColumnViewColumnHeaderCellValue(
                                    value,
                                    idx,
                                    id
                                  );
                                }}
                              />
                            );
                          }
                        )}
                      </Box>
                    );
                  }
                )}
                {!isReadModeOnly && (
                  <button
                    ref={addColumnButtonRef}
                    type="button"
                    className="add-content column"
                    onClick={() => {
                      if (isRowColumnHeader) {
                        addRowColumnHeaderTypeColumn();
                      } else {
                        addColumnHeaderTypeColumn();
                      }
                    }}
                  >
                    <AddIcon fontSize="small" />
                  </button>
                )}
              </>
            ) : (
              <Box className="loading-container">
                <Box className="table-source-container">
                  {loaderOverlay.map((_item: any, idx: number) => (
                    <Box key={idx} className="row">
                      {generateDisabledCell()}
                      {generateDisabledCell()}
                      {generateDisabledCell()}
                      {generateDisabledCell()}
                      {generateDisabledCell()}
                    </Box>
                  ))}
                </Box>
                <Box className="loading-overlay">
                  <CircularProgress />
                </Box>
              </Box>
            )}
          </Box>
          {!isReadModeOnly && (
            <button
              type="button"
              className="add-content row"
              onClick={() => {
                if (isRowColumnHeader) {
                  addRowColumnHeaderTypeRow();
                } else {
                  addColumnHeaderTypeRow();
                }
              }}
            >
              <AddIcon fontSize="small" />
            </button>
          )}
        </Box>
      </DialogContent>
      {!isReadModeOnly && (
        <DialogActions>
          {(canUpdateTableContent || canUpdateMeta) && (
            <Button
              onClick={resetTableData}
              color="primary"
              size="medium"
              variant="outlined"
            >
              Reset table data
            </Button>
          )}
          <LoadingButton
            onClick={updateTable}
            disabled={!canUpdateTableContent && !canUpdateMeta}
            color="primary"
            size="medium"
            variant="contained"
            loading={updatingTable}
          >
            Update table
          </LoadingButton>
        </DialogActions>
      )}
    </Wrapper>
  );
};

export default TableSourceDialog;

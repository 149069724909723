import React, { useState } from "react";
import { AITable } from "models/api/response.types";
import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  IconButton,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { useSelector } from "react-redux";
import {
  selectCurrentOrganizationId,
  selectUser,
} from "store/features/session/slice";
import { useAITables } from "api/aiService";
import { LoadingButton } from "@mui/lab";

const StyledDialog = styled(Dialog)(() => ({
  "& .MuiDialogContent-root": {
    display: "flex",
    flexDirection: "column",
    "& .table-name": {
      marginBottom: "1rem",
    },
    "& .visibility-container": {
      width: "fit-content",
      marginLeft: "0.5rem",
      "& .MuiButtonBase-root": {
        marginRight: "0.5rem",
      },
    },
  },
}));

const AITableDialog: React.FC<{
  setOpen: (newTable?: AITable) => void;
  documentIds?: number[];
  tableToUpdate?: AITable;
}> = ({ documentIds, tableToUpdate, setOpen }) => {
  const currentOrganizationId = useSelector(selectCurrentOrganizationId);
  const user = useSelector(selectUser);
  const { createAITableMutation, updateAITableMutation } = useAITables(
    currentOrganizationId
  );
  const [loading, setLoading] = useState(false);
  const [tableName, setTableName] = useState<string>(
    tableToUpdate?.meta.name || ""
  );
  const [visibility, setVisibility] = useState<string>(
    tableToUpdate?.visibility || "private"
  );

  const createNewTable = () => {
    if (user) {
      setLoading(true);
      const newTable = {
        document_ids: documentIds || [],
        meta: {
          name: tableName,
        },
        organization_id: currentOrganizationId || 0,
        user_id: user?.id,
        visibility,
      };
      createAITableMutation.mutate(newTable, {
        onSettled: (data) => {
          setLoading(false);
          setOpen(data?.data || undefined);
        },
      });
    }
  };

  const updateTable = () => {
    if (tableToUpdate) {
      setLoading(true);
      updateAITableMutation.mutate(
        {
          tableId: tableToUpdate.id,
          body: {
            meta: {
              name: tableName,
              last_edited: new Date().toISOString(),
            },
            visibility,
          },
        },
        {
          onSettled: () => {
            setLoading(false);
            setOpen();
          },
        }
      );
    }
  };

  return (
    <StyledDialog open fullWidth maxWidth="sm" onClose={() => setOpen()}>
      <DialogTitle>
        {tableToUpdate ? "Update template" : "Create new template"}
        <IconButton onClick={() => setOpen()}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          value={tableName}
          className="session-name"
          color="primary"
          variant="outlined"
          fullWidth
          name="sessionName"
          type="text"
          placeholder="Enter template name"
          size="small"
          onChange={(e) => {
            setTableName(e.target.value);
          }}
          onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>) => {
            if (event.key === "Enter" && tableName.length > 0) {
              event.preventDefault();
              if (tableToUpdate) {
                updateTable();
              } else {
                createNewTable();
              }
            }
          }}
        />
        <FormControl className="visibility-container">
          <FormControlLabel
            control={
              <Checkbox
                size="small"
                className="primary"
                checked={visibility === "shared"}
                onChange={() => {
                  if (visibility === "private") {
                    setVisibility("shared");
                  } else {
                    setVisibility("private");
                  }
                }}
              />
            }
            label={
              <Typography color="textSecondary" variant="body2">
                Make the template shared across workspace
              </Typography>
            }
          />
        </FormControl>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          variant="contained"
          size="medium"
          color="primary"
          disabled={tableName.length === 0}
          loading={loading}
          onClick={() => {
            if (tableToUpdate) {
              updateTable();
            } else {
              createNewTable();
            }
          }}
        >
          {tableToUpdate ? "Update" : "Create"}
        </LoadingButton>
      </DialogActions>
    </StyledDialog>
  );
};

export default AITableDialog;

import { authService } from "api";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { cleanup } from "store/features/session/slice";
import { goToLogin } from "utils/accountsUtils";
import useTelemetry, { telemetryAction } from "utils/useTelemetry";

const Logout: React.FC = () => {
  const dispatch = useDispatch();
  const { logAction } = useTelemetry();

  useEffect(() => {
    authService.logout().then(() => {
      goToLogin();
      localStorage.clear();
      dispatch(cleanup());
      logAction(telemetryAction.logout, {});
    });
  }, []);

  return null;
};

export default Logout;

import { companyFeatures } from "company-config";

/* eslint-disable import/prefer-default-export */
export const individualFreePlan = {
  name: "Free",
  price: 0,
  yearlyPrice: 0,
  oldPrice: 0,
  oldYearlyPrice: 0,
  lookupKey: "petal_free_plan",
  yearlyKey: "",
  wechatKey: "",
  wechatYearlyKey: "",
  features: [
    ["2 GB cloud storage", "1 member", "1 guest"],
    ["100 questions"],
    [
      "Pre-built Template Library",
      "Sticky Note",
      "Single-doc Chat",
      "Export/API",
    ],
    ["Yes"],
  ],
};

export const individualsPlans = [
  {
    name: "Starter",
    price: 20,
    yearlyPrice: 200,
    features: [
      ["2 GB cloud storage", "1 member", "1 guest"],
      ["500 questions"],
      [
        "Pre-built Template Library",
        "Sticky Note",
        "Single-doc Chat",
        "Export/API",
      ],
      ["Yes"],
    ],
    lookupKey: "indstarterplan",
    yearlyKey: "yearlyindstarterplan",
  },
  {
    name: "Pro",
    price: 30,
    yearlyPrice: 300,
    features: [
      ["10 GB cloud storage", "1 member", "3 guest"],
      ["1000 questions"],
      [
        "Pre-built Template Library",
        "Sticky Note",
        "Single-doc Chat",
        "Multi-doc Chat",
        "AI Assistant",
        "Export/API",
      ],
      ["Yes"],
    ],
    lookupKey: "indproplan",
    yearlyKey: "yearlyindproplan",
  },
];

export const teamsPlans = [
  {
    name: "Team Starter",
    price: 130,
    yearlyPrice: 1560,
    features: [
      ["50 GB cloud storage", "5 members", "10 guests"],
      ["5000 questions"],
      [
        "Pre-built Template Library",
        "Sticky Note",
        "Single-doc Chat",
        "Multi-doc Chat",
        "AI Assistant",
        "Export/API",
      ],
      ["Yes"],
    ],
    lookupKey: "teamstarterplan",
    yearlyKey: "yearlyteamstarterplan",
  },
  {
    name: "Team Advanced",
    price: 350,
    yearlyPrice: 4200,
    features: [
      ["500 GB cloud storage", "20 members", "100 guests"],
      ["10000 questions"],
      [
        "Pre-built Template Library",
        "Sticky Note",
        "Single-doc Chat",
        "Multi-doc Chat",
        "AI Assistant",
        "Export/API",
      ],
      ["Yes"],
    ],
    lookupKey: "teamadvancedplan",
    yearlyKey: "yearlyteamadvancedplan",
  },
];

export const enterprisePlan = {
  name: "Enterprise",
  price: 99999,
  features: [
    ["Custom cloud storage", "Custom members", "Custom guests"],
    ["Custom question limit/month"],
    [
      "Pre-built Template Library",
      "Sticky Note",
      "Single-doc Chat",
      "Multi-doc Chat",
      "AI Assistant",
      "Export/ API",
      "Private GPT - Available*",
    ],
    ["Priority"],
  ],
};

export const featureCatogeries = [
  "General",
  "AI",
  "Reference Manager",
  "Support",
];

export interface BillingPlan {
  name: string;
  price: number;
  yearlyPrice?: number;
  features: string[][];
  lookupKey?: string;
  yearlyKey?: string;
}

export const addons = [
  {
    name: `Storage`,
    lookup_keys: ["extragb"],
    price: 1.29,
  },
  {
    name: `Message Credits"`,
    lookup_keys: ["500aicredits"],
    price: 10,
  },
];

export const planKeys = [
  "indstarterplan",
  "yearlyindstarterplan",
  "indproplan",
  "yearlyindproplan",
  "teamstarterplan",
  "yearlyteamstarterplan",
  "teamadvancedplan",
  "yearlyteamadvancedplan",
];

// we can get current plan base on ai credit limit
// notice any further changes on ai credit limit will
// reflect this object
export const AI_CREDIT_PLAN_KEY: { [key: number]: string } = {
  500: "Starter plan",
  1000: "Pro plan",
  5000: "Team Starter plan",
  10000: "Team Advanced plan",
  // free plan and enterprise handle separately
};

const priceOrder = [
  "indstarterplan",
  "indproplan",
  "teamstarterplan",
  "teamadvancedplan",
];

export const comparePlans = (key1?: string, key2?: string) => {
  if (!key1 || !key2) {
    return ["error", "error"];
  }

  if (
    (key1.includes("yearly") && key2.includes("yearly")) ||
    (!key1.includes("yearly") && !key2.includes("yearly"))
  ) {
    // $ monthly to $$$ monthly - immediate upgrade prorate
    // $ yearly to $$$ yearly - prorate normally
    if (planKeys.indexOf(key1) < planKeys.indexOf(key2)) {
      return ["upgrade", "immediate"];
    }
    // $$$ monthly to $ monthly - $$$ service till end then $ service
    // $$$ yearly plan to $ yearly plan - $$$ service till end of period then $ service
    if (planKeys.indexOf(key1) > planKeys.indexOf(key2)) {
      return ["downgrade", "end of period"];
    }
  }

  const key1Index = priceOrder.indexOf(key1.replace("yearly", ""));
  const key2Index = priceOrder.indexOf(key2.replace("yearly", ""));
  if (!key1.includes("yearly") && key2.includes("yearly")) {
    // $ monthly to $$$ yearly - immediate upgrade prorate
    if (key1Index < key2Index) {
      return ["upgrade", "immediate"];
    }
    // $$$ monthly to $ yearly - $$$ until end then switch
    if (key1Index > key2Index) {
      return ["downgrade", "end of period"];
    }
    // $ monthly to $ yearly - end of month
    // $$$ monthly to $$$ yearly - start yearly plan at end of month
    if (key1Index === key2Index) {
      return ["switch", "end of period"];
    }
  }

  if (key1.includes("yearly") && !key2.includes("yearly")) {
    // $ yearly to $$$ monthly - contact us
    if (key1Index < key2Index) {
      return ["contact us", "contact us"];
    }
    // $$$ yearly plan to $ monthly plan - service till end then switch to monthly
    if (key1Index > key2Index) {
      return ["downgrade", "end of period"];
    }

    // $$$ yearly plan to $$$ monthly plan - service till end then switch to monthly
    // $ yearly to $ monthly - service till end then switch to monthly
    if (key1Index === key2Index) {
      return ["switch", "end of period"];
    }
  }
  return ["error", "error"];
};

export const isFreePlan = (orgPlans: string[]) => {
  return !orgPlans.some((plan) => plan.includes("plan"));
};

export const isCollectionLimitExceeded = (
  orgPlans: string[],
  customCollectionsLength: number
) => {
  return customCollectionsLength >= 2 && isFreePlan(orgPlans);
};

export const isAnnotationLimitExceeded = (
  orgPlans: string[],
  annotationLength: number,
  type: "create" | undefined
) => {
  return annotationLength >= 3 && isFreePlan(orgPlans) && type === "create";
};

export const canUseMultidocChat = (aiCreditLimit: number) => {
  return aiCreditLimit >= 1000;
};

export const canUseAiCreate = (aiCreditLimit: number) => {
  return aiCreditLimit >= 1000;
};

export const isTrialExist = (org_created_time?: string) => {
  // trial is not valid for white label projects
  if (!companyFeatures.app.isTrialExists) {
    return false;
  }

  const currentDate: Date = new Date();

  // check if org created time less than 7 days
  if (org_created_time) {
    const passedDate: Date = new Date(org_created_time);
    const timeDifferencePassed: number =
      currentDate.getTime() - passedDate.getTime();
    const daysDifferencePassed: number =
      timeDifferencePassed / (1000 * 60 * 60 * 24);
    if (daysDifferencePassed < 7) {
      return true;
    }
  }

  return false;
};

import React, { useMemo } from "react";
import { Box, styled } from "@mui/material";
import { addons, individualsPlans, teamsPlans } from "utils/plans";
import { useOrg } from "pages/WorkspaceSettings";
import { useOrganizationUsage } from "api/organizationService";
import Plan from "./Plan";
import Addon from "./Addon";

const Wrapper = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  gap: "1rem",
}));

const WorkspacePlan: React.FC = () => {
  const { org: currentOrg } = useOrg();
  const { organizationUsage } = useOrganizationUsage(currentOrg.id);

  const { addonsToShow, currentPlan } = useMemo(() => {
    if (organizationUsage) {
      const { plans } = organizationUsage;
      const plan = plans.filter(
        (p: any) =>
          !addons.some((addon) => {
            return addon.lookup_keys.includes(p.lookup_key || "");
          })
      );
      const existingAddons = plans.filter((p: any) =>
        addons.some((addon) => {
          return addon.lookup_keys.includes(p.lookup_key || "");
        })
      );
      let planToReturn;
      if (plan[0]) {
        const result = plan[0];
        const planToShow = [...individualsPlans, ...teamsPlans].find((p) => {
          return (
            result.lookup_key === p.lookupKey ||
            result.lookup_key === p.yearlyKey
          );
        });
        const nextPlanToShow = [...individualsPlans, ...teamsPlans].find(
          (p) => {
            return (
              result.next_key === p.lookupKey || result.next_key === p.yearlyKey
            );
          }
        );
        if (planToShow?.name === nextPlanToShow?.name) {
          planToReturn = {
            ...result,
            at_period_end: result.at_period_end,
            lookup_key: result.next_key,
            next_key: "",
          };
        } else {
          planToReturn = result;
        }
      }
      return {
        currentPlan: planToReturn,
        addonsToShow: existingAddons,
      };
    }
    return {
      addonsToShow: undefined,
      currentPlan: undefined,
    };
  }, [organizationUsage]);

  return (
    <Wrapper>
      <Plan
        lookupKey={currentPlan?.lookup_key || "petal_free_plan"}
        canCancel={
          currentPlan &&
          currentPlan.at_period_end !== "cancel" &&
          currentPlan.at_period_end !== "change"
        }
        isCanceled={
          currentPlan &&
          (currentPlan.at_period_end === "cancel" ||
            currentPlan.at_period_end === "change")
        }
        cancelTime={currentPlan?.period_end}
        subscriptionId={currentPlan?.subscription_id}
        isYearly={currentPlan?.lookup_key?.includes("yearly")}
      />
      {((currentPlan?.at_period_end === "change" && currentPlan.next_key) ||
        (currentPlan?.at_period_end === "cancel" && !currentPlan.next_key)) && (
        <Plan
          lookupKey={currentPlan?.next_key || "petal_free_plan"}
          canCancel={!!currentPlan?.next_key}
          subscriptionId={currentPlan?.subscription_id}
          showNext
          nextTime={currentPlan?.period_end}
          isYearly={currentPlan?.next_key?.includes("yearly")}
        />
      )}
      {addonsToShow?.map((addon: any) => (
        <Addon key={addon.subscription_id} addon={addon} />
      ))}
    </Wrapper>
  );
};

export default WorkspacePlan;

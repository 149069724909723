/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint @typescript-eslint/no-var-requires: "off" */
import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";
import thunkMiddleware from "redux-thunk";
import localForage from "localforage";
import { persistStore, persistReducer, createMigrate } from "redux-persist";
import rootReducer from "store/reducers/index";
import { v4 as uuidv4 } from "uuid";
import { initialState as settingsInitialState } from "store/features/settings/slice";
import {
  SearchType,
  initialState as browserInitialState,
} from "store/features/browser/slice";
import { initialState as sessionInitialState } from "store/features/session/slice";
import { initialState as generalInitialState } from "store/features/general/slice";
import { initialState as documentViewerInitialState } from "store/features/documentViewer/slice";

const migrations = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  3: (state: any) =>
    // migration to keep only device state
    ({
      ...state,
      search: {
        query: "",
        projectFilter: 0,
        viewState: "simple",
        doSearch: false,
        propertyForTest: "reproduce_issue_302_version3_migration",
      },
    }),
  // migration to add annotationTabSelectedMeta property
  4: (state: any) => ({
    ...state,
    projectBrowser: {
      viewState: "simple",
      autoRefresh: true,
      revealDocument: {
        documentId: 0,
        projectId: 0,
        done: true,
      },
      revealColumn: {
        columnName: "",
        projectId: 0,
        done: true,
      },
      openCreateProject: false,
      openUpdateProject: false,
      openDeleteProject: false,
      updateProjectId: 0,
      deleteProjectId: 0,
      annotationTabSelectedMeta: {
        id: -1,
      },
      trashMode: false,
      pending: false,
      isOpenDoiImport: false,
      selectedDocuments: [],
      bibtexImport: [],
      isOpenBibTeXImport: false,
    },
  }),
  // migration to add gridColumnState
  5: (state: any) => ({
    ...state,
    projectBrowser: {
      ...state.projectBrowser,
      gridColumnState: {},
    },
  }),
  // migration to add autoSize
  6: (state: any) => ({
    ...state,
    projectBrowser: {
      ...state.projectBrowser,
      gridColumnState: {
        columnInfoByProjectId: {},
        columnAutoSizeByProjectId: {},
      },
    },
  }),
  // migration to add latestDataUpdate
  7: (state: any) => ({
    ...state,
    projectBrowser: {
      ...state.projectBrowser,
      latestDataUpdate: new Date().toISOString(),
    },
  }),
  // migration to add mainTabSelected
  // need for checking in which tab user is staying
  // document-grid by default the first tab
  8: (state: any) => ({
    ...state,
    projectBrowser: {
      ...state.projectBrowser,
      mainTabSelected: "document-grid",
    },
  }),
  9: (state: any) => ({
    ...state,
    projectBrowser: {
      ...state.projectBrowser,
      isLoading: [],
    },
  }),
  // migration to use new session slice instead of auth
  10: (state: any) => ({
    ...state,
    session: {
      user: null,
      organizationId: 0,
    },
  }),
  // migration to add new auth email handoff between login and signup
  11: (state: any) => ({
    ...state,
    session: {
      ...state.session,
      authEmailHandoff: { email: "" },
    },
  }),
  // migration to add annotations
  12: (state: any) => ({
    ...state,
    annotations: {},
  }),
  // replace organizationId with organization
  13: (state: any) => {
    const { session } = state;
    return {
      ...state,
      sesssion: {
        ...session,
        organization: null,
      },
    };
  },
  // add document sort logic
  14: (state: any) => ({
    ...state,
    projectBrowser: {
      ...state.projectBrowser,
      documentSort: {
        key: "date-created",
        order: "descending",
      },
    },
  }),
  // add preauth route to session
  15: (state: any) => ({
    ...state,
    session: {
      ...state.session,
      preAuthRoute: undefined,
    },
  }),
  // persist right sidebar visibility
  16: (state: any) => ({
    ...state,
    projectBrowser: {
      ...state.projectBrowser,
      tabVisibility: undefined,
    },
  }),
  // update reducers (remove old code)
  17: (state: any) => ({
    ...state,
    tags: {
      modeBrowse: "or",
      projectBrowserSelectedIds: [],
      tagListFilter: "all",
    },
    documents: {
      uploadedDocuments: [],
    },
    projectBrowser: {
      revealDocument: {
        documentId: 0,
        projectId: 0,
        done: true,
      },
      annotationTabSelectedMeta: {
        id: -1,
      },
      mainTabSelected: "document-grid",
      gridColumnState: {
        columnInfoByProjectId: {},
        columnAutoSizeByProjectId: {},
      },
      documentSort: {
        key: "date-created",
        order: "descending",
      },
      focusSearchInput: false,
      currentProject: undefined,
      tabVisibility: undefined,
    },
  }),
  // update reducers (removeunused code)
  18: (state: any) => ({
    ...state,
    projectBrowser: {
      revealDocument: {
        documentId: 0,
        projectId: 0,
        done: true,
      },
      annotationTabSelectedMeta: {
        id: -1,
      },
      gridColumnState: {
        columnInfoByProjectId: {},
        columnAutoSizeByProjectId: {},
      },
      documentSort: {
        key: "date-created",
        order: "descending",
      },
      focusSearchInput: false,
      currentProject: undefined,
      tabVisibility: undefined,
    },
  }),
  // update reducers (removeunused code)
  19: (state: any) => ({
    ...state,
    projectBrowser: {
      ...state.projectBrowser,
      viewState: "documents",
    },
    search: {
      query: "",
      type: "metadata",
      scope: "current-project",
    },
  }),
  // update reducers (removeunused code)
  20: (state: any) => ({
    ...state,
    search: {
      query: "",
      type: "metadata",
      scope: "current-project",
    },
  }),
  // update reducers (add collections)
  21: (state: any) => ({
    ...state,
    collections: {
      selectedCollections: [],
    },
  }),
  // update reducers (add collections)
  22: (state: any) => {
    const { ui } = state;
    return {
      ...state,
      ui: {
        ...ui,
        tabLayout: null,
      },
    };
  },
  // update reducers (add annotation default color and doc viewer tool mode)
  23: (state: any) => ({
    ...state,
    ui: {
      ...state.ui,
      annotationDialogDefaultColor: "#00D084",
      documentViewerToolMode: "select",
    },
  }),
  // update reducers (add annotation sort)
  24: (state: any) => ({
    ...state,
    projectBrowser: {
      ...state.projectBrowser,
      annotationSort: {
        key: "date-created",
        order: "descending",
      },
    },
  }),
  // update reducers (add tag sort)
  25: (state: any) => ({
    ...state,
    projectBrowser: {
      ...state.projectBrowser,
      tagSort: {
        key: "frequency",
        order: "descending",
      },
    },
  }),
  // update reducers (update tool modes)
  26: (state: any) => ({
    ...state,
    ui: {
      title: "Petal",
      tabLayout: null,
      annotationDialogDefaultColor: "#00D084",
      documentViewerToolMode: {
        fileAct: "select",
        annotationAct: "highlight",
        active: "select",
      },
    },
  }),
  // update reducers (update tool modes)
  27: (state: any) => ({
    ...state,
    ui: {
      ...state.ui,
      selectedCitation: undefined,
    },
  }),
  // update reducers (draggable documentId)
  28: (state: any) => ({
    ...state,
    collections: {
      ...state.collections,
      draggableDocumentId: undefined,
    },
  }),
  // update reducers (add sessionId)
  29: (state: any) => ({
    ...state,
    session: {
      ...state.session,
      sessionId: uuidv4(),
      enableTelemetry: true,
    },
  }),
  // update reducers (update selectedCollections)
  30: (state: any) => ({
    ...state,
    collections: {
      selectedCollection: undefined,
      selectedTreeCollection: [],
      draggableDocumentId: undefined,
      loadingCollection: undefined,
    },
  }),
  // update reducers (add isEditproperties to redux)
  31: (state: any) => ({
    ...state,
    ui: {
      ...state.ui,
      isEditProperties: false,
    },
  }),
  // add sing in helpers
  32: (state: any) => ({
    ...state,
    ui: {
      ...state.ui,
      signInHelpers: {
        isFirstTimeSignIn: false,
        viewMenuHelper: false,
        faqHelper: false,
        toolModeHelper: false,
        lookupHelper: false,
        organiationHelper: false,
      },
    },
  }),
  // refactoring state
  33: (state: any) => ({
    ...state,
    general: {
      title: "Petal",
      annotationDialogDefaultColor: "#00D084",
      alerts: [],
      authEmailHandoff: "",
      preAuthRoute: undefined,
      revealDocument: {
        organizationId: 0,
        documentId: 0,
        done: true,
      },
      annotationTabSelectedMeta: {
        id: -1,
      },
      documentSort: {
        key: "date-created",
        order: "descending",
      },
      annotationSort: {
        key: "date-created",
        order: "descending",
      },
      tagSort: {
        key: "frequency",
        order: "descending",
      },
    },
    projectBrowser: {
      gridColumnState: {
        columnInfoByProjectId: {},
      },
      collections: {
        selectedCollection: undefined,
        selectedTreeCollection: [],
        draggableDocumentId: undefined,
        loadingCollection: undefined,
      },
      tags: {
        selectedIds: [],
        modeBrowse: "or",
      },
      search: {
        query: "",
        isSearch: false,
        type: "fulltext",
        scope: "current-project",
      },
      focusSearchInput: false,
      focusLookUpInput: false,
      viewState: "document-list",
      tabLayout: null,
      selectedCitation: undefined,
      isLookUpTab: false,
      isEditProperties: false,
    },
    documentViewer: {
      toolBarMode: {
        fileAct: "select",
        annotationAct: "highlight",
        active: "select",
      },
    },
    session: {
      user: undefined,
      currentOrganizationId: undefined,
      currentProjectId: undefined,
      sessionId: uuidv4(),
      enableTelemetry: true,
    },
    user: {
      signInHelpers: {
        isFirstTimeSignIn: false,
        projectHelper: false,
        viewMenuHelper: false,
        faqHelper: false,
        toolModeHelper: false,
        lookupHelper: false,
      },
    },
  }),
  // adding organization sort
  34: (state: any) => ({
    ...state,
    general: {
      ...state.general,
      organizationSort: {
        key: "name",
        order: "descending",
      },
    },
  }),
  // success organization creation state
  35: (state: any) => ({
    ...state,
    general: {
      ...state.general,
      successOrganizationCreation: false,
    },
  }),
  // collection migration
  36: (state: any) => ({
    ...state,
    general: generalInitialState,
    session: sessionInitialState,
    browser: browserInitialState,
    documentViewer: documentViewerInitialState,
  }),
  // add petl library state
  37: (state: any) => ({
    ...state,
    browser: {
      ...state.browser,
      isPetalLibrary: false,
    },
    documentViewer: {
      ...state.documentViewer,
      focusLookUpInput: false,
    },
  }),
  38: (state: any) => ({
    ...state,
    browser: {
      ...state.browser,
      viewState: "full",
      sideBarOpen: false,
      previewView: "hidden",
      publicLibraryMode: false,
    },
  }),
  // add exceed limit notifier
  39: (state: any) => ({
    ...state,
    browser: {
      ...state.browser,
      showExceededLimitNotifier: true,
    },
  }),
  // add ai reducer
  40: (state: any) => ({
    ...state,
    ai: {
      ...state.ai,
      textSelectionQuestion: undefined,
    },
  }),
  // add ai language
  41: (state: any) => ({
    ...state,
    ai: {
      ...state.ai,
      aiLanguage: "English (US)",
    },
  }),
  // add collections to chat and Template Library
  42: (state: any) => ({
    ...state,
    browser: {
      ...state.browser,
      collections: {
        selectedDocumentsCollectionId: undefined,
        selectedAIChatCollectionId: undefined,
        selectedAITableCollectionId: undefined,
      },
    },
  }),
  // add global collection selector
  43: (state: any) => ({
    ...state,
    browser: {
      ...state.browser,
      selectedCollectionId: undefined,
    },
  }),
  // add settings slice
  44: (state: any) => ({
    ...state,
    settings: {
      isSidebarOpen: false,
    },
  }),
  // add search type
  45: (state: any) => ({
    ...state,
    browser: {
      ...state.browser,
      search: {
        ...state.browser.search,
        searchType: SearchType.fulltext,
      },
    },
  }),
  // update sort
  46: (state: any) => ({
    ...state,
    browser: {
      ...state.browser,
      documentSort: {
        key: "date-created",
        order: "descending",
      },
    },
  }),
  // selectedDocumentFilters
  47: (state: any) => ({
    ...state,
    browser: {
      ...state.browser,
      selectedDocumentFilters: {
        authors: [],
        journals: [],
        years: [],
        titles: [],
        tags: [],
      },
    },
  }),
};

const persistConfig = {
  key: "root",
  storage: localForage,
  timeout: 20000,
  version: 47,
  whitelist: [
    "general",
    "browser",
    "documentViewer",
    "session",
    "user",
    "ai",
    "settings",
  ],
  migrate: createMigrate(migrations, { debug: true }),
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const middlewares = [thunkMiddleware];
if (process.env.NODE_ENV === `development`) {
  /* eslint-disable global-require */
  const { createLogger } = require(`redux-logger`);
  /* eslint-enable global-require */
  const loggerMiddleware = createLogger({
    collapsed: true,
    duration: true,
    timestamp: true,
    level: "info",
  });

  middlewares.push(loggerMiddleware);
}

export const store = createStore(
  persistedReducer,
  composeWithDevTools(applyMiddleware(...middlewares))
);

export const persistor = persistStore(store);

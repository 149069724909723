export type AILanguage =
  | "English (US)"
  | "English (UK)"
  | "Chinese (Simplified)"
  | "Chinese (Traditional)"
  | "Korean"
  | "Japanese"
  | "German"
  | "French"
  | "Spanish"
  | "Italian"
  | "Ukrainian"
  | "Russian"
  | "Slovak"
  | "Czech"
  | "Polish"
  | "Albanian"
  | "Arabic"
  | "Armenian"
  | "Awadhi"
  | "Azerbaijani"
  | "Bashkir"
  | "Basque"
  | "Belarusian"
  | "Bengali"
  | "Bhojpuri"
  | "Bosnian"
  | "Bulgarian"
  | "Catalan"
  | "Chhattisgarhi"
  | "Croatian"
  | "Danish"
  | "Dogri"
  | "Dutch"
  | "Estonian"
  | "Faroese"
  | "Finnish"
  | "Galician"
  | "Georgian"
  | "Greek"
  | "Gujarati"
  | "Haryanvi"
  | "Hebrew"
  | "Hindi"
  | "Hungarian"
  | "Indonesian"
  | "Irish"
  | "Javanese"
  | "Kannada"
  | "Kashmiri"
  | "Kazakh"
  | "Konkani"
  | "Kyrgyz"
  | "Latvian"
  | "Lithuanian"
  | "Macedonian"
  | "Maithili"
  | "Malay"
  | "Maltese"
  | "Marathi"
  | "Marwari"
  | "Moldovan"
  | "Mongolian"
  | "Montenegrin"
  | "Nepali"
  | "Norwegian"
  | "Oriya"
  | "Pashto"
  | "Persian (Farsi)"
  | "Portuguese"
  | "Brazilian Portuguese"
  | "Punjabi"
  | "Rajasthani"
  | "Romanian"
  | "Sanskrit"
  | "Santali"
  | "Serbian"
  | "Sindhi"
  | "Sinhala"
  | "Thai"
  | "Urdu"
  | "Uzbek"
  | "Vietnamese"
  | "Welsh";

export const AILanguages: AILanguage[] = [
  "English (US)",
  "English (UK)",
  "Chinese (Simplified)",
  "Chinese (Traditional)",
  "Korean",
  "Japanese",
  "German",
  "French",
  "Spanish",
  "Italian",
  "Ukrainian",
  "Russian",
  "Slovak",
  "Czech",
  "Polish",
  "Albanian",
  "Arabic",
  "Armenian",
  "Awadhi",
  "Azerbaijani",
  "Bashkir",
  "Basque",
  "Belarusian",
  "Bengali",
  "Bhojpuri",
  "Bosnian",
  "Bulgarian",
  "Catalan",
  "Chhattisgarhi",
  "Croatian",
  "Danish",
  "Dogri",
  "Dutch",
  "Estonian",
  "Faroese",
  "Finnish",
  "Galician",
  "Georgian",
  "Greek",
  "Gujarati",
  "Haryanvi",
  "Hebrew",
  "Hindi",
  "Hungarian",
  "Indonesian",
  "Irish",
  "Javanese",
  "Kannada",
  "Kashmiri",
  "Kazakh",
  "Konkani",
  "Korean",
  "Kyrgyz",
  "Latvian",
  "Lithuanian",
  "Macedonian",
  "Maithili",
  "Malay",
  "Maltese",
  "Marathi",
  "Marwari",
  "Moldovan",
  "Mongolian",
  "Montenegrin",
  "Nepali",
  "Norwegian",
  "Oriya",
  "Pashto",
  "Persian (Farsi)",
  "Polish",
  "Portuguese",
  "Brazilian Portuguese",
  "Punjabi",
  "Rajasthani",
  "Romanian",
  "Russian",
  "Sanskrit",
  "Santali",
  "Serbian",
  "Sindhi",
  "Sinhala",
  "Slovak",
  "Spanish",
  "Thai",
  "Ukrainian",
  "Urdu",
  "Uzbek",
  "Vietnamese",
  "Welsh",
];

export const AiLanguagesShortcuts: { [key: string]: string } = {
  "English (US)": "US",
  "English (UK)": "UK",
  "Chinese (Simplified)": "简体中文",
  "Chinese (Traditional)": "繁體中文",
  Korean: "한국어",
  Japanese: "日本語",
  German: "DE",
  French: "FR",
  Spanish: "ES",
  Italian: "IT",
  Ukrainian: "УK",
  Russian: "PУ",
  Slovak: "SK",
  Czech: "CS",
  Polish: "PL",
  Albanian: "SQ",
  Arabic: "AR",
  Armenian: "HY",
  Awadhi: "AWA",
  Azerbaijani: "AZ",
  Bashkir: "BA",
  Basque: "EU",
  Belarusian: "BE",
  Bengali: "BN",
  Bhojpuri: "BHO",
  Bosnian: "BS",
  Bulgarian: "BG",
  Catalan: "CA",
  Chhattisgarhi: "HNE",
  Croatian: "HR",
  Danish: "DA",
  Dogri: "DOI",
  Dutch: "NL",
  Estonian: "ET",
  Faroese: "FO",
  Finnish: "FI",
  Galician: "GL",
  Georgian: "KA",
  Greek: "EL",
  Gujarati: "GU",
  Haryanvi: "BGJ",
  Hebrew: "HE",
  Hindi: "HI",
  Hungarian: "HU",
  Indonesian: "ID",
  Irish: "GA",
  Javanese: "JV",
  Kannada: "KN",
  Kashmiri: "KS",
  Kazakh: "KK",
  Konkani: "KOK",
  Kyrgyz: "KY",
  Latvian: "LV",
  Lithuanian: "LT",
  Macedonian: "MK",
  Maithili: "MAI",
  Malay: "MS",
  Maltese: "MT",
  Marathi: "MR",
  Marwari: "MWR",
  Moldovan: "MO",
  Mongolian: "MN",
  Montenegrin: "ME",
  Nepali: "NE",
  Norwegian: "NO",
  Oriya: "OR",
  Pashto: "PS",
  "Persian (Farsi)": "FA",
  Portuguese: "PT",
  "Brazilian Portuguese": "PT-BR",
  Punjabi: "PA",
  Rajasthani: "RAJ",
  Romanian: "RO",
  Sanskrit: "SA",
  Santali: "SAT",
  Serbian: "SR",
  Sindhi: "SD",
  Sinhala: "SI",
  Thai: "TH",
  Urdu: "UR",
  Uzbek: "UZ",
  Vietnamese: "VI",
  Welsh: "CY",
};

export type SelectedTextAiOperations =
  | "summarize"
  | "paraphrase"
  | "translate"
  | "explain"
  | "suggest"
  | "identify";

export const getSelectedTextAiOperationDescription = (
  action: SelectedTextAiOperations,
  language: AILanguage,
  text: string
) => {
  if (action === "summarize") {
    return `Summarize the following text: ${text}`;
  }
  if (action === "paraphrase") {
    return `Paraphrase the following text: ${text}`;
  }
  if (action === "translate") {
    return `Translate the following text into ${language}: ${text}`;
  }
  if (action === "explain") {
    return `Explain the following text using simpler language: ${text}`;
  }
  if (action === "suggest") {
    return `Suggest search terms based on the following text: ${text}`;
  }
  return `Identify key points from the following text: ${text}`;
};

export const terminal_status_names = [
  "success",
  "failure",
  "revoked",
  "rejected",
  "dep_failure",
  "None",
];

export const maxAmountOfCharacters = 400;

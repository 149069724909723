/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectUser, setUser } from "store/features/session/slice";
import Joyride, { CallBackProps } from "react-joyride";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import { isSidebarOpen, setSideBarOpen } from "store/features/browser/slice";
import useTelemetry, { telemetryAction } from "utils/useTelemetry";
import { authService } from "api";
import OnboardingTooltip from "./Tooltip";

export const AiCreateProjectDashboardOnboarding: React.FC = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const sideBarOpen = useSelector(isSidebarOpen);
  const user = useSelector(selectUser);
  const smScreen = useMediaQuery(theme.breakpoints.down("lg"));
  const { logAction } = useTelemetry();

  const completeCurrentOnboarding = (action: string) => {
    if (user) {
      authService
        .updateUser(user.id, {
          meta: {
            ...user.meta,
            citeOnboarding: {
              ...user.meta.citeOnboarding,
              aiCreate: {
                ...user.meta.citeOnboarding.aiCreate,
                [action]: new Date().toISOString(),
              },
            },
          },
        })
        .then(({ data }) => {
          dispatch(setUser({ ...user, ...data }));
        })
        .catch((err) => err);
    }
  };

  const dashboardSteps = [
    {
      target: ".main-container",
      placement: "center" as const,
      disableBeacon: true,
      disableOverlayClose: true,
      disableCloseOnEsc: true,
      content: (
        <Box
          className="onboarding-step"
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          <Typography variant="h6">Welcome to AI Assistant!</Typography>
          <Typography variant="body1" color="textSecondary">
            AI Assistant helps you generate complex written works based on
            knowledge contained within your documents.
          </Typography>
        </Box>
      ),
    },
    {
      target: ".templates-container",
      placement: "center" as const,
      disableBeacon: true,
      disableOverlayClose: true,
      disableCloseOnEsc: true,
      content: (
        <Box
          className="onboarding-step"
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          <Typography variant="h6">Start a new project</Typography>
          <Typography variant="body1" color="textSecondary">
            To start a new project, you may select one of the presets and
            customize it further, or create your own from scratch.
          </Typography>
        </Box>
      ),
    },
  ];

  const dashboardCallback = (data: CallBackProps) => {
    const { action, lifecycle, index } = data;

    // start with the sidebar open, so that on the second step,
    // the tooltip can reference it. Then close it for the last step.
    if (
      action === "start" &&
      lifecycle === "init" &&
      index === 0 &&
      sideBarOpen &&
      smScreen
    ) {
      dispatch(setSideBarOpen(false));
    }

    if (
      action === "close" &&
      lifecycle === "complete" &&
      index === dashboardSteps.length - 1
    ) {
      logAction(telemetryAction.onboarding_flow, {
        method: "ai_create_dashboard_onboarding_completed",
      });
      completeCurrentOnboarding("dashboard");
    }
  };

  if (user?.meta?.citeOnboarding?.aiCreate?.dashboard) {
    return null;
  }

  return (
    <Joyride
      run
      steps={dashboardSteps}
      tooltipComponent={OnboardingTooltip}
      callback={dashboardCallback}
      floaterProps={{
        styles: {
          wrapper: {
            zIndex: 1501,
          },
        },
      }}
      styles={{
        options: {
          zIndex: 1500,
        },
      }}
    />
  );
};

export const AiCreateSelectReferencesOnboarding = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const sideBarOpen = useSelector(isSidebarOpen);
  const user = useSelector(selectUser);
  const smScreen = useMediaQuery(theme.breakpoints.down("lg"));
  const { logAction } = useTelemetry();

  const completeCurrentOnboarding = (action: string) => {
    if (user) {
      authService
        .updateUser(user.id, {
          meta: {
            ...user.meta,
            citeOnboarding: {
              ...user.meta.citeOnboarding,
              aiCreate: {
                ...user.meta.citeOnboarding.aiCreate,
                [action]: new Date().toISOString(),
              },
            },
          },
        })
        .then(({ data }) => {
          dispatch(setUser({ ...user, ...data }));
        })
        .catch((err) => err);
    }
  };

  const referencesSteps = [
    {
      target: "body",
      placement: "center" as const,
      disableBeacon: true,
      disableOverlayClose: true,
      disableCloseOnEsc: true,
      content: (
        <Box
          className="onboarding-step"
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          <Typography variant="h6">Review Sources</Typography>
          <Typography variant="body1" color="textSecondary">
            Confirm which sources you want to use for your writing. Our AI has
            already tried to identify the most relevant documents based on your
            topic specification.
          </Typography>
          <Typography variant="body1" color="textSecondary">
            In the next step, you will review the AI&apos;s attempt to build a
            knowledge base by coming up with a list of Q&As for each document.
          </Typography>
        </Box>
      ),
    },
  ];

  const referencesCallback = (data: CallBackProps) => {
    const { action, lifecycle, index } = data;
    if (
      action === "start" &&
      lifecycle === "init" &&
      index === 0 &&
      sideBarOpen &&
      smScreen
    ) {
      dispatch(setSideBarOpen(false));
    }
    if (
      action === "close" &&
      lifecycle === "complete" &&
      index === referencesSteps.length - 1
    ) {
      logAction(telemetryAction.onboarding_flow, {
        method: "ai_create_select_references_onboarding_completed",
      });
      completeCurrentOnboarding("selectReferences");
    }
  };

  if (user?.meta?.citeOnboarding?.aiCreate?.selectReferences) {
    return null;
  }

  return (
    <Joyride
      run
      steps={referencesSteps}
      tooltipComponent={OnboardingTooltip}
      callback={referencesCallback}
      floaterProps={{
        styles: {
          wrapper: {
            zIndex: 1501,
          },
        },
      }}
      styles={{
        options: {
          zIndex: 1500,
        },
      }}
    />
  );
};

export const AiCreateExtractKnowledgeOnboarding = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const sideBarOpen = useSelector(isSidebarOpen);
  const user = useSelector(selectUser);
  const smScreen = useMediaQuery(theme.breakpoints.down("lg"));
  const { logAction } = useTelemetry();

  const completeCurrentOnboarding = (action: string) => {
    if (user) {
      authService
        .updateUser(user.id, {
          meta: {
            ...user.meta,
            citeOnboarding: {
              ...user.meta.citeOnboarding,
              aiCreate: {
                ...user.meta.citeOnboarding.aiCreate,
                [action]: new Date().toISOString(),
              },
            },
          },
        })
        .then(({ data }) => {
          dispatch(setUser({ ...user, ...data }));
        })
        .catch((err) => err);
    }
  };

  const steps = [
    {
      target: "body",
      placement: "center" as const,
      disableBeacon: true,
      disableOverlayClose: true,
      disableCloseOnEsc: true,
      content: (
        <Box
          className="onboarding-step"
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          <Typography variant="h6">Extract Knowledge</Typography>
          <Typography variant="body1" color="textSecondary">
            Add questions to extract knowledge from your reference documents. To
            start, the AI has already generated some for you. You can add
            additional questions by writing your own or asking the AI to
            generate one. If you do not like a response to a question, you can
            re-generate it or edit it directly. These Q&As serve as the basis of
            the knowledge the AI will draw upon when writing in the next step.
          </Typography>
        </Box>
      ),
    },
  ];

  const callback = (data: CallBackProps) => {
    const { action, lifecycle, index } = data;
    if (
      action === "start" &&
      lifecycle === "init" &&
      index === 0 &&
      sideBarOpen &&
      smScreen
    ) {
      dispatch(setSideBarOpen(false));
    }
    if (
      action === "close" &&
      lifecycle === "complete" &&
      index === steps.length - 1
    ) {
      logAction(telemetryAction.onboarding_flow, {
        method: "ai_create_extract_knowledge_onboarding_completed",
      });
      completeCurrentOnboarding("extractKnowledge");
    }
  };

  if (user?.meta?.citeOnboarding?.aiCreate?.extractKnowledge) {
    return null;
  }

  return (
    <Joyride
      run
      steps={steps}
      tooltipComponent={OnboardingTooltip}
      callback={callback}
      floaterProps={{
        styles: {
          wrapper: {
            zIndex: 1501,
          },
        },
      }}
      styles={{
        options: {
          zIndex: 1500,
        },
      }}
    />
  );
};

export const AiCreateWriteOnboarding = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const sideBarOpen = useSelector(isSidebarOpen);
  const user = useSelector(selectUser);
  const smScreen = useMediaQuery(theme.breakpoints.down("lg"));
  const { logAction } = useTelemetry();

  const completeCurrentOnboarding = (action: string) => {
    if (user) {
      authService
        .updateUser(user.id, {
          meta: {
            ...user.meta,
            citeOnboarding: {
              ...user.meta.citeOnboarding,
              aiCreate: {
                ...user.meta.citeOnboarding.aiCreate,
                [action]: new Date().toISOString(),
              },
            },
          },
        })
        .then(({ data }) => {
          dispatch(setUser({ ...user, ...data }));
        })
        .catch((err) => err);
    }
  };

  useEffect(() => {
    if (
      user?.meta?.citeOnboarding?.aiCreate?.dashboard &&
      user?.meta?.citeOnboarding?.aiCreate?.selectReferences &&
      user?.meta?.citeOnboarding?.aiCreate?.extractKnowledge &&
      user?.meta?.citeOnboarding?.aiCreate?.write
    ) {
      completeCurrentOnboarding("completed");
    }
  }, [user]);

  const steps = [
    {
      target: "body",
      placement: "center" as const,
      disableBeacon: true,
      disableOverlayClose: true,
      disableCloseOnEsc: true,
      content: (
        <Box
          className="onboarding-step"
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          <Typography variant="h6">Write</Typography>
          <Typography variant="body1" color="textSecondary">
            Write your content by adding blocks. You can add Text blocks to
            write your own content, or AI blocks to generate content based on
            your references and questions. To get started, you can ask the AI to
            generate an outline for you, which you can then customize.
          </Typography>
          <Typography variant="body1" color="textSecondary">
            When creating an AI block, you will need to provide a prompt that
            describes what the AI should write for that section. You can also
            provide some example text from a similar document that you&apos;d
            like the AI to emulate.
          </Typography>
          <Typography variant="body1" color="textSecondary">
            When you are done, you can export the document.
          </Typography>
        </Box>
      ),
    },
  ];

  const callback = (data: CallBackProps) => {
    const { action, lifecycle, index } = data;
    if (
      action === "start" &&
      lifecycle === "init" &&
      index === 0 &&
      sideBarOpen &&
      smScreen
    ) {
      dispatch(setSideBarOpen(false));
    }
    if (
      action === "close" &&
      lifecycle === "complete" &&
      index === steps.length - 1
    ) {
      logAction(telemetryAction.onboarding_flow, {
        method: "ai_create_write_onboarding_completed",
      });
      completeCurrentOnboarding("write");
    }
  };

  if (user?.meta?.citeOnboarding?.aiCreate?.write) {
    return null;
  }

  return (
    <Joyride
      run
      steps={steps}
      tooltipComponent={OnboardingTooltip}
      callback={callback}
      floaterProps={{
        styles: {
          wrapper: {
            zIndex: 1501,
          },
        },
      }}
      styles={{
        options: {
          zIndex: 1500,
        },
      }}
    />
  );
};

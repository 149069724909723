import axios, { AxiosPromise } from "axios";
import {
  UserUpdateBody,
  UserDetailed,
  SuccessResponse,
  AuthBody,
  AccessTokenResponse,
  SignupUserPermittedResponse,
  SignupEmailSendBody,
  Referral,
  SignupCodeAcceptBody,
} from "models/api/response.types";

const login = (requestBody: AuthBody): AxiosPromise<AccessTokenResponse> => {
  return axios.post<AccessTokenResponse>(`/api/auth`, requestBody);
};

const checkEmailPermission = (
  email: string
): AxiosPromise<SignupUserPermittedResponse> => {
  const encodedEmail = encodeURIComponent(email);
  return axios.get<SignupUserPermittedResponse>(
    `/api/signup/user/permitted?email=${encodedEmail}`
  );
};

const sendVerificationCode = (
  requestBody: SignupEmailSendBody
): AxiosPromise<Referral> => {
  return axios.post<Referral>(`/api/signup/email/send`, requestBody);
};

const authUsingVerificationCode = (
  requestBody: SignupCodeAcceptBody
): AxiosPromise<AccessTokenResponse> => {
  return axios.post<AccessTokenResponse>(
    `/api/signup/code/accept`,
    requestBody
  );
};

const authUsingGoogle = (token: string): AxiosPromise<AccessTokenResponse> => {
  return axios.get(`/api/oauth/auth/google?access_token=${token}`);
};

const authUsingPassword = (
  requestBody: AuthBody
): AxiosPromise<AccessTokenResponse> => {
  return axios.post<AccessTokenResponse>(`/api/auth`, requestBody);
};

const createNewUser = (
  requestBody: SignupEmailSendBody
): AxiosPromise<Referral> => {
  return axios.post<Referral>("/api/signup/create", requestBody);
};

const sendForgotPasswordVerificationCode = (
  requestBody: SignupEmailSendBody
): AxiosPromise<SuccessResponse> => {
  return axios.post("/api/signup/forgot_password/email/send", requestBody);
};

const acceptForgotPasswordVerificationCode = (
  requestBody: SignupCodeAcceptBody
): AxiosPromise<AccessTokenResponse> => {
  return axios.post("/api/signup/forgot_password/code/accept", requestBody);
};

const updateUser = (
  userId: number,
  requestBody: Partial<UserUpdateBody>
): AxiosPromise<UserDetailed> => {
  return axios.post<UserDetailed>(`/api/user/${userId}/update`, requestBody);
};

const createPersonalOrganization = (
  userId: number
): AxiosPromise<UserDetailed> => {
  return axios.post<UserDetailed>(`/api/user/${userId}/organization/create`);
};

const fetchUserInfo = (userId: number): AxiosPromise<UserDetailed> => {
  return axios.get<UserDetailed>(`/api/user/${userId}`);
};

const logout = (): AxiosPromise<SuccessResponse> => {
  return axios.delete(`/api/logout`);
};

export const authService = {
  login,
  checkEmailPermission,
  sendVerificationCode,
  authUsingVerificationCode,
  authUsingPassword,
  createNewUser,
  updateUser,
  createPersonalOrganization,
  fetchUserInfo,
  sendForgotPasswordVerificationCode,
  acceptForgotPasswordVerificationCode,
  authUsingGoogle,
  logout,
};

export default authService;
